import { useApolloClient } from "@apollo/client";
import { Tooltip } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { MapPin, MoreVertical } from "react-feather";
import { useParams } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import { OverlayContext } from "../../context/OverlayProvider";
import { StorageContext } from "../../context/StorageProvider";
import { GET_USER_BY_LINK } from "../../graphql/queries/Users";
import Button from "../buttons/Button";
import Link from "../links/Link";
import Modal from "../modals/Modal";
import ShareLinkModal from "../modals/impl/ShareLinkModal";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import ProfilePicture from "../view/impl/ProfilePicture";
import Spacer from "../view/impl/Spacer";
import DevpageButton from "./devpage/DevpageButton";
import styles from "./styles/User.module.css";
import NotFound from "./NotFound";

export default function User({ data, userDeets, preview }) {
  const { link } = useParams();

  if (!link && !preview) {
    return <NotFound link={link} />;
  }

  const [error, setError] = useState(null);

  const { userDetails } = useContext(StorageContext);

  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const [user, setUser] = useState(userDeets);

  const { appearanceSettings, setAppearanceSettings } =
    useContext(ConfigContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  const openShareLinkModal = () => {
    openOverlay(
      <Modal title="Share this devpa.ge">
        <ShareLinkModal user={user} />
      </Modal>
    );
  };

  useEffect(() => {
    async function getUser() {
      const response = await client
        .query({
          query: GET_USER_BY_LINK,
          variables: {
            link: link,
          },
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          setError({
            type: 1,
            message: error.message,
          });
        });
      if (response && response.data && response.data.getUserByLink) {
        setUser(response.data.getUserByLink);
        document.title = response.data.getUserByLink.name + " | devpa.ge";
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setError({
          type: 2,
          message: "User not found.",
        });
      }
    }

    if (user === null || user === undefined) {
      if (link) {
        getUser();
      }
    }
  }, []);

  if (error && error.type === 2) {
    return <NotFound link={link} />;
  }

  if (loading || !user) {
    return undefined;
  }

  if (
    user.private &&
    !preview &&
    (!userDetails || user.email !== userDetails.email)
  ) {
    return <NotFound />;
  }

  const { darkMode } = useContext(ConfigContext);
  return (
    <View
      className={styles.background}
      style={{
        backgroundImage:
          preview && appearanceSettings.backgroundType === 2
            ? "linear-gradient(" +
              appearanceSettings.gradientColor1 +
              ", " +
              appearanceSettings.gradientColor2 +
              ")"
            : !preview &&
              user.publishedAppearance &&
              JSON.parse(user.publishedAppearance).backgroundType === 2
            ? "linear-gradient(" +
              JSON.parse(user.publishedAppearance).gradientColor1 +
              ", " +
              JSON.parse(user.publishedAppearance).gradientColor2 +
              ")"
            : "none",
        backgroundColor:
          preview && appearanceSettings.backgroundType === 1
            ? appearanceSettings.backgroundColor
            : !preview &&
              user.publishedAppearance &&
              JSON.parse(user.publishedAppearance).backgroundType === 1
            ? JSON.parse(user.publishedAppearance).backgroundColor
            : "transparent",
      }}
      centerVertically
      centerHorizontally
    >
      {preview &&
        appearanceSettings.backgroundType === 3 &&
        appearanceSettings.backgroundURL && (
          <img
            className={styles.backgroundImage}
            src={appearanceSettings.backgroundURL}
          />
        )}
      {!preview &&
        user.publishedAppearance &&
        JSON.parse(user.publishedAppearance).backgroundType === 3 &&
        JSON.parse(user.publishedAppearance).backgroundURL && (
          <img
            className={styles.backgroundImage}
            src={JSON.parse(user.publishedAppearance).backgroundURL}
          />
        )}
      <Button
        style={{
          borderColor: appearanceSettings ? appearanceSettings.buttonBorderColor : JSON.parse(user.publishedAppearance)
          .buttonBorderColor,
          backgroundColor: appearanceSettings ? appearanceSettings.buttonBackgroundColor : JSON.parse(user.publishedAppearance)
            .buttonBackgroundColor,
        }}
        onClick={openShareLinkModal}
        className={styles.shareButton}
      >
        <MoreVertical
          color={appearanceSettings ? appearanceSettings.buttonColor : JSON.parse(user.publishedAppearance).buttonColor}
          size={17}
        />
      </Button>
      <View className={styles.container} centerVertically vertical>
        <Spacer size="large" />
        <Spacer size="medium" />
        <ProfilePicture name={user.name} url={user.picture} />
        <Spacer size="medium" />
        <View centerHorizontally centerVertically>
          <H6
            style={{
              color: appearanceSettings
                ? appearanceSettings.nameColor
                : JSON.parse(user.publishedAppearance).nameColor,
            }}
            className={styles.name}
          >
            {user.name}
          </H6>
          {user.verification > 0 && (
            <Tooltip
              className={styles.userTooltip}
              label="This user has had their identity verified."
            >
              <img
                className={styles.verified}
                src={
                  user.verification === 2
                    ? "./assets/svgs/verified.svg"
                    : "./assets/svgs/verified.svg"
                }
              />
            </Tooltip>
          )}
        </View>
        <Spacer size="medium" />
        {user.bio && (
          <Paragraph
            style={{
              color: appearanceSettings
                ? appearanceSettings.bioColor
                : JSON.parse(user.publishedAppearance).bioColor,
            }}
            size="large"
            className={styles.bio}
          >
            {user.bio}
          </Paragraph>
        )}
        {user.bio &&
          (user.location ? <Spacer size="medium" /> : <Spacer size="large" />)}

        {user.location && (
          <View centerVertically centerHorizontally>
            <MapPin
              className={styles.mapPin}
              color={
                appearanceSettings
                  ? appearanceSettings.locationColor
                  : JSON.parse(user.publishedAppearance).locationColor
              }
              size={14}
            />
            <Paragraph
              style={{
                color: appearanceSettings
                  ? appearanceSettings.locationColor
                  : JSON.parse(user.publishedAppearance).locationColor,
              }}
              size="small"
            >
              {user.location}
            </Paragraph>
          </View>
        )}
        {user.location && <Spacer size="large" />}
        {data &&
          data.length > 0 &&
          data.map(
            (section) =>
              section &&
              section.visible !== false &&
              section.links.length > 0 &&
              section.links.filter((link) => link.visible !== false).length >
                0 &&
              section.links &&
              section.links.length > 0 && (
                <View vertical>
                  <H6
                    style={{ color: appearanceSettings.sectionColor }}
                    className={styles.categoryTitle}
                  >
                    {section.name}
                  </H6>
                  <View gap="medium" className={styles.cellContainer}>
                    {section.links &&
                      section.links.length > 0 &&
                      section.links.map(
                        (link) =>
                          link.visible && (
                            <DevpageButton
                              containerStyle={{
                                borderRadius:
                                  appearanceSettings.buttonBorderRadius + "px",
                                borderColor:
                                  appearanceSettings.buttonBorderColor,
                                borderWidth: "1px",
                                borderStyle: "solid",
                                backgroundColor:
                                  appearanceSettings.buttonBackgroundColor,
                              }}
                              style={{ color: appearanceSettings.buttonColor }}
                              url={link.uri}
                              title={link.slug}
                              description={link.description}
                              thumbnail={link.thumbnail}
                            />
                          )
                      )}
                  </View>
                  <Spacer size="medium" />
                </View>
              )
          )}
        {!data &&
          user.published &&
          JSON.parse(user.published).length > 0 &&
          JSON.parse(user.published).map(
            (section) =>
              section &&
              section.visible !== false &&
              section.links.length > 0 &&
              section.links.filter((link) => link.visible !== false).length >
                0 &&
              section.links &&
              section.links.length > 0 && (
                <View vertical>
                  <H6
                    style={{
                      color: JSON.parse(user.publishedAppearance).sectionColor,
                    }}
                    className={styles.categoryTitle}
                  >
                    {section.name}
                  </H6>
                  <View gap="medium" className={styles.cellContainer}>
                    {section.links &&
                      section.links.length > 0 &&
                      section.links.map(
                        (link) =>
                          link.visible && (
                            <DevpageButton
                              containerStyle={{
                                borderRadius:
                                  JSON.parse(user.publishedAppearance)
                                    .buttonBorderRadius + "px",
                                borderColor: JSON.parse(
                                  user.publishedAppearance
                                ).buttonBorderColor,
                                borderWidth: "1px",
                                borderStyle: "solid",
                                backgroundColor: JSON.parse(
                                  user.publishedAppearance
                                ).buttonBackgroundColor,
                              }}
                              style={{
                                color: JSON.parse(user.publishedAppearance)
                                  .buttonColor,
                              }}
                              url={link.uri}
                              title={link.slug}
                              description={link.description}
                              thumbnail={link.thumbnail}
                            />
                          )
                      )}
                  </View>
                  <Spacer size="medium" />
                </View>
              )
          )}
        <Spacer size="medium" />
        <Link to="/">
          <img
            className={`${styles.logo}`}
            src={
              darkMode
                ? "./assets/images/logo_white.png"
                : "./assets/images/logo.png"
            }
            alt="devpage Logo"
          />
        </Link>
        <Spacer size="large" />
        <Spacer size="large" />
      </View>
    </View>
  );
}
