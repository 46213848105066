import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import { GET_USER_BY_HASH } from "../../graphql/queries/Users";
import { useApolloClient } from "@apollo/client";
import { ConfigContext } from "../../context/ConfigProvider";
import { StorageContext } from "../../context/StorageProvider";
import { UPDATE_USER } from "../../graphql/mutations/Users";

export default function Auth() {
  
  const { id } = useParams();

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  const { setUserDetails } = useContext(StorageContext);

  const navigate = useNavigate();

  async function getUser() {
    const response = await client
      .query({
        query: GET_USER_BY_HASH,
        variables: {
          hash: id,
        },
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
        setUpdate(true);
        return error;
      });
    if (response.data) {
      const user = response.data.getUserByHash;
      if (user !== null) {
        await client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              email: user.email,
              hashed: "",
              hash: "",
            },
          })
          .catch((error) => {
            console.error("Error updating user:", error);
          });
        setUserDetails(user);
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    getUser();
  }, [id]);

  return undefined;
}
