import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AlertCircle, GitHub, GitMerge, Inbox } from "react-feather";
import { ConfigContext } from "../../context/ConfigProvider";
import { EmailContext } from "../../context/EmailProvider";
import {
  GET_USER_BY_EMAIL,
  VERIFY_GOOGLE_AUTH,
} from "../../graphql/queries/Users";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Container from "../navigation/Container";
import RadioButton from "../radiobutton/RadioButton";
import radioButtonStyles from "../radiobutton/styles/RadioButton.module.css";
import H5 from "../text/heading/H5";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import styles from "./styles/Login.module.css";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { StorageContext } from "../../context/StorageProvider";
import { useNavigate } from "react-router-dom";
import Spacer from "../view/impl/Spacer";
import { SOFT_DELETE } from "../../graphql/mutations/Users";
import Button from "../buttons/Button";
import axios from "axios";

export default function Login(props) {
  const { setUserDetails } = useContext(StorageContext);

  const { sendEmail } = useContext(EmailContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { update, setUpdate } = useContext(ConfigContext);

  const textInputRef = useRef(null);

  const [emailInput, setEmailInput] = useState("");

  async function setEmail(email) {
    setError(null);
    setEmailInput(email);
  }

  const [linkSent, setLinkSent] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    document.title = 'Sign in to your devpa.ge account – No password needed';
  }, []);

  async function sendLoginLink() {
    const emailInput = textInputRef.current.value;
    if (emailInput.length === 0) {
      setError({
        type: 2,
        message: "Please enter your email address.",
      });
      setUpdate(true);
      return error;
    } else if (!validateEmail(emailInput)) {
      setError({
        type: 2,
        message: "Please enter a valid email address.",
      });
      setUpdate(true);
      return error;
    } else {
      setError(null);
    }
    const response = await client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: emailInput,
        },
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
        setUpdate(true);
        return error;
      });

    if (response == null || response.data == null) {
      setError({
        type: 2,
        message: "No user found with that email address.",
      });
      setUpdate(true);
      return null;
    }
    const user = response.data.getUserByEmail;
    if (user === null) {
      setError({
        type: 2,
        message: "No user found with that email address.",
      });
      setUpdate(true);
      return null;
    }
    if (user.archived) {
      setError({
        type: 2,
        message: (
          <View vertical gap="small">
            <Paragraph className={styles.errorText} size="small">
              Your account has been archived and will be permanently deleted
              within 30 days from the day you archived it.
            </Paragraph>
            <Button
              size="small"
              className={styles.noPadButton}
              onClick={() => restoreAccount(user.email)}
            >
              Click here to restore your account
            </Button>
          </View>
        ),
      });
      setUpdate(true);
      return null;
    }
    const emailResponse = await sendEmail({
      from: "noreply@hyre.so",
      fromName: "Devpa.ge (no-reply)",
      to: user.email,
      subject: "Login to devpa.ge",
      template: "login",
      templateParams: {
        name: user.name,
        hash: "sha256",
        sessionEmail: user.email, // Passing this will tie the hash to the email.
      },
    });
    setLinkSent(true);
    setUpdate(true);
    return emailResponse;
  }

  async function restoreAccount(email) {
    let response = await client
      .mutate({
        mutation: SOFT_DELETE,
        variables: {
          email: email,
          archived: false,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.updateUser) {
      setError(null);
      await sendEmail({
        from: "noreply@hyre.so",
        fromName: "Devpa.ge (no-reply)",
        to: response.data.updateUser.email,
        subject: "Login to devpa.ge",
        template: "login",
        templateParams: {
          name: response.data.updateUser.name,
          hash: "sha256",
          sessionEmail: response.data.updateUser.email, // Passing this will tie the hash to the email.
        },
      });
      setLinkSent(true);
      setUpdate(true);
    }
  }

  const fetchUserEmail = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const { email } = response.data;
      return email;

      // Now you have the user's email, you can fetch the user details from your database.
      // Your code to fetch user details goes here...
    } catch (error) {
      console.error("Failed to fetch user email:", error);
    }
  };

  async function signInWithGoogle(accessToken) {
    const response = await client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: await fetchUserEmail(accessToken),
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data && response.data.getUserByEmail) {
      const user = response.data.getUserByEmail;
      if (user !== null) {
        if (user.archived) {
          setError({
            type: 1,
            message: (
              <View vertical gap="small">
                <Paragraph className={styles.errorText} size="small">
                  Your account has been archived and will be permanently deleted
                  within 30 days from the day you archived it.
                </Paragraph>
                <Button
                  className={styles.noPadButton}
                  size="small"
                  onClick={() => restoreAccount(user.email)}
                >
                  Click here to restore your account
                </Button>
              </View>
            ),
          });
        } else {
          setUserDetails(user);
          navigate("/dashboard");
        }
      } else {
        navigate("/join");
      }
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      signInWithGoogle(response.access_token);
    },
    onError: (errorResponse) => {
      setError({
        type: 2,
        message: "Something went wrong. Please try again.",
      });
      setUpdate(true);
    },
    onFailure: () => {
      setUpdate(true);
    },
  });

  return (
    <Container className={styles.container}>
      <View className={styles.loginContainer}>
        <View className={styles.loginWrapper}>
          <H5>{"Welcome back. 👋"}</H5>
          <Paragraph size="small" className={styles.paragraph}>
            Log in to your account, no password needed.
          </Paragraph>
          <Spacer size="small" />
          <View vertical gap="small">
            <SecondaryButton
              size="medium"
              onClick={googleLogin}
              className={styles.button}
            >
              <img
                className={styles.googleIcon}
                src={"./assets/images/google.png"}
              />
              Sign in with Google
            </SecondaryButton>
          </View>
          <View className={styles.separatorContainer}>
            <Separator className={styles.separator}></Separator>
            <Paragraph size="small">or</Paragraph>
            <Separator className={styles.separator}></Separator>
          </View>
          <div className={styles.form}>
            <TextInput
              size="medium"
              ref={textInputRef}
              value={emailInput}
              onChange={(e) => setEmail(e.target.value)}
              className={`${styles.input} ${
                error !== null && error.type === 2 && styles.errorBorder
              }`}
              placeholder="Enter your email"
            />
            {error !== null && (error.type === 1 || error.type === 2) && (
              <View
                className={styles.errorContainer}
                centerHorizontally
                centerVertically
              >
                <AlertCircle
                  className={styles.errorIcon}
                  color="var(--red)"
                  size="13"
                ></AlertCircle>
                <Paragraph size="small" className={styles.errorText}>
                  {error.message}
                </Paragraph>
              </View>
            )}
            {linkSent ? (
              <PrimaryButton
                disabled
                size="medium"
                spinner
                className={styles.button}
              >
                A magic link has been sent.
              </PrimaryButton>
            ) : (
              <PrimaryButton
                size="medium"
                spinner
                onClick={() => sendLoginLink()}
                className={styles.button}
              >
                <Inbox size={20} className={styles.magicIcon} /> Send me a magic
                link
              </PrimaryButton>
            )}
          </div>
          <Paragraph size="small" className={styles.signUpLinkContainer}>
            {props.signup
              ? "Already have an account?"
              : "Don't have an account?"}{" "}
            <Link to={props.signup ? "/login" : "/join"}>
              {props.signup ? "Sign In" : "Join now"}
            </Link>
          </Paragraph>
        </View>
      </View>
    </Container>
  );
}
