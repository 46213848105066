import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query {
    users {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const GET_USER_BY_HASH = gql`
  query getUserByHash($hash: String!) {
    getUserByHash(hash: $hash) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const GET_USER_BY_LINK = gql`
  query getUserByLink($link: String!) {
    getUserByLink(link: $link) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
      sections {
        sectionId
        name
        links {
          linkId
          sectionId
          uri
          owner
          slug
          archived
          visible
          description
          thumbnail
        }
        archived
        visible
      }
    }
  }
`;

export const LINK_EXISTS = gql`
  query linkExists($link: String!) {
    linkExists(link: $link)
  }
`;

export const VERIFY_GOOGLE_AUTH = gql`
  query verifyGoogleAuth($token: String!) {
    verifyGoogleAuth(token: $token) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;
