import React, { useContext } from "react";
import { Code, GitHub, GitMerge, Linkedin, Twitter } from "react-feather";
import Button from "../../buttons/Button";
import Paragraph from "../../text/paragraph/Paragraph";
import Spacer from "../../view/impl/Spacer";
import styles from "../styles/User.module.css";
import Link from "../../links/Link";
import H6 from "../../text/heading/H6";
import View from "../../view/View";
import { ConfigContext } from "../../../context/ConfigProvider";

export default function DevpageButton({
  containerStyle,
  style,
  url,
  title,
  description,
  thumbnail,
}) {
  const { darkMode } = useContext(ConfigContext);

  return (
    <Link
      to={url}
      target="_blank"
      style={containerStyle}
      linkClassName={styles.cellLink}
      className={styles.cell}
    >
      <View>
        {thumbnail && thumbnail.length > 0 && (
          <img
            className={`${styles.thumbnail}`}
            src={thumbnail}
            alt={"Thumbnail"}
          />
        )}
        <H6 style={style} className={styles.link}>
          {title || url}
        </H6>
      </View>
      {description && (
        <Paragraph style={style} className={styles.paragraph}>
          {description}
        </Paragraph>
      )}
    </Link>
  );
}
