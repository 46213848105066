import React, { useContext, useEffect, useState } from "react";
import {
  AlertTriangle,
  Check,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  X,
} from "react-feather";
import { ConfigContext } from "../../context/ConfigProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import RadioButton from "../radiobutton/RadioButton";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import RadioCell from "./RadioCell";
import styles from "./styles/RadioCellContainer.module.css";
import Button from "../buttons/Button";

export default function RadioCellContainer(props) {

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const { setUpdate } = useContext(ConfigContext);

  const [questionIndex, setQuestionIndex] = useState(0);

  const [selected, setSelected] = useState(props.selected ? props.selected : 0);

  const [selectedList, setSelectedList] = useState(props.selected ? [props.selected] : []);

  const [dismissed, setDismissed] = useState(false);

  const [handshake, setHandshake] = useState(false);

  const [introduced, setIntroduced] = useState(false);

  const [currentInput, setCurrentInput] = useState("");

  const [accepted, setAccepted] = useState(false);

  function nextStep() {
    setTimeout(() => {
      setDismissed(true);
    }, 300);
  }

  useEffect(() => {
    if (dismissed) {
      setTimeout(() => {
        setQuestionIndex(questionIndex + 1);
        setHandshake(true);
        setTimeout(() => {
          setUpdate(true);
          setIntroduced(true);
          setTimeout(() => {
            setDismissed(false);
            setHandshake(false);
            setIntroduced(false);
          }, 200);
        }, 200);
      }, 200);
    }
  }, [dismissed, setUpdate]);

  useEffect(() => {
    if (props.onCallback) {
      props.onCallback(questionIndex, selected, currentInput, selectedList);
    }
    setCurrentInput("");
    setSelected(props.selected ? props.selected : 0);
  }, [questionIndex]);

  if (questionIndex === props.data.questions.length) {
    return (
      <View
        vertical
        centerVertically
        className={`${styles.fadedOut} ${styles.fadeIn}`}
      >
        <View
          className={`${styles.checkboxContainer} ${
            props.error && styles.errorCheckboxContainer
          }`}
        >
          <View
            className={`${styles.checkbox} ${
              props.error && styles.errorCheckbox
            }`}
          >
            {props.error ? (
              <X size={18} color="var(--neutral-1000)" />
            ) : (
              <Check size={18} color="var(--neutral-1000)" />
            )}
          </View>
        </View>
        <Spacer size="large" />
        <H6 className={styles.thankYouTitle} center>
          {props.error ? "Oops! Our bad." : props.data.finalMessage}
        </H6>
        <Spacer size="medium" />
        <Paragraph className={styles.maxLengthParagraph} size="small" center>
          {props.error
            ? props.error
            : props.warning
            ? props.warning
            : props.data.finalMessage}
        </Paragraph>
        <Spacer size="large" />
        <PrimaryButton to="/" className={styles.ctaButton} size="small">
          <ChevronLeft color="var(--neutral-1000)" size={18} />
          Return home
        </PrimaryButton>
      </View>
    );
  }

  const disabled = (props.data.questions[questionIndex].checkboxRequired &&
    !accepted) ||
  (props.data.questions[questionIndex].questionType === 1 &&
    (props.data.questions[questionIndex].unique
      ? selected === 0
      : false)) ||
  (props.data.questions[questionIndex].questionType === 2 &&
    (props.data.questions[questionIndex].inputType === "email"
      ? currentInput === "" || !validateEmail(currentInput)
      : currentInput === ""));
  return (
    <View
      vertical
      centerVertically
      className={`${styles.questionLabel} ${
        dismissed && styles.dismissedLabel
      } ${handshake && styles.handshake} ${
        introduced && styles.introducedLabel
      }`}
    >
      {props.data.questions[questionIndex].questionTitle != null && (
        <Spacer size="large" />
      )}
      {props.data.questions[questionIndex].questionTitle != null && (
        <Spacer size="large" />
      )}
      {props.data.questions[questionIndex].questionTitle != null && (
        <H5 className={styles.questionLabel}>
          {props.data.questions[questionIndex].questionTitle}
        </H5>
      )}
      {props.data.questions[questionIndex].questionSubtitle != null && (
        <Paragraph center size="small">
          {props.data.questions[questionIndex].questionSubtitle}
        </Paragraph>
      )}
      {props.data.questions[questionIndex].questionSubtitle != null && (
        <Spacer size="large" />
      )}
      {props.data.questions[questionIndex].questionSubtitle != null && (
        <Spacer size="large" />
      )}
      {props.data.questions[questionIndex].question != null && (
        <H6 className={styles.questionLabel}>
          {props.data.questions[questionIndex].question}
        </H6>
      )}
      {props.data.questions[questionIndex].question != null && (
        <Spacer size="large" />
      )}
      <View
        gap="medium"
        className={`${
          props.data.questions[questionIndex].questionType === 1
            ? (props.rows === 3 ? styles.overflow3 : styles.overflow)
            : styles.baseOverflow
        }`}
      >
        {props.data.questions[questionIndex].questionType === 1 &&
          props.data.questions[questionIndex].options.map((item) => (
            <RadioCell
              icon={item.icon}
              onClick={() => {
                if (selected === item.id) {
                  if (props.data.questions[questionIndex].unique && !props.data.questions[questionIndex].preventUnselect) {
                    setSelected(0); // Reset selected item to 0
                    if (props.onSelectCallback) {
                      props.onSelectCallback(0, props.type);
                    }
                  } else {
                    setSelectedList((prevList) => {
                      const updatedList = { ...prevList };
                      delete updatedList[item.id]; // Remove item from selectedList
                      return updatedList;
                    });
                  }
                } else {
                  if (props.data.questions[questionIndex].unique) {
                    setSelected(item.id); // Set selected item to the current item
                    if (props.onSelectCallback) {
                      props.onSelectCallback(item.id, props.type);
                    }
                  } else if (item.id in selectedList) {
                    setSelectedList((prevList) => {
                      const updatedList = { ...prevList };
                      delete updatedList[item.id]; // Remove item from selectedList
                      return updatedList;
                    });
                  } else {
                    setSelectedList((prevList) => {
                      const updatedList = { ...prevList, [item.id]: item }; // Add item to selectedList
                      return updatedList;
                    });
                  }
                }
              }}
              selected={
                ((props.data.questions[questionIndex].unique
                  ? selected === item.id
                  : item.id in selectedList))
              }
              key={item.id}
              transparentBackground={props.data.questions[questionIndex].transparentBackground}
              rows={props.rows}
              component={item.component}
              titleSize={item.titleSize}
              title={item.title}
              subtitle={item.subtitle}
            />
          ))}
        {props.data.questions[questionIndex].questionType === 2 && (
          <TextInput
            value={currentInput}
            onChange={(event) => setCurrentInput(event.target.value)}
            className={styles.input}
            type={props.data.questions[questionIndex].inputType}
            placeholder={props.data.questions[questionIndex].inputPlaceholder}
          />
        )}
      </View>
      {props.data.questions[questionIndex].checkbox && (
        <View className={`${styles.questionLabel}`} vertical centerVertically>
          <Spacer size="small" />
          <RadioButton
            onChange={(newValue) => setAccepted(newValue)}
            className={styles.checkboxRadioButton}
            component={
              <Paragraph className={styles.checkboxText} size="small">
                {questionIndex === 2
                  ? "I would like to receive proposals from devpage"
                  : "I have read and agree with our"}
                {questionIndex === 0 && (
                  <Link to="/privacy-policy"> Privacy Policy</Link>
                )}
                .
              </Paragraph>
            }
          />
        </View>
      )}
      {!props.data.questions[questionIndex].hideButton && (
        <Spacer size="large" />
      )}
      {!props.data.questions[questionIndex].hideButton && (
        <Spacer size="small" />
      )}
      {!props.data.questions[questionIndex].hideButton && (
        <View centerHorizontally>
        <PrimaryButton
          onClick={nextStep}
          linkClassName={styles.link}
          disabled={
            disabled
          }
          spinner
          className={`${styles.ctaButton}`}
        >
          {questionIndex === props.data.questions.length - 1
            ? "Create account"
            : "Next Step"}
          <ChevronRight color={disabled ? "var(--neutral-100)" : "var(--neutral-1000)"} size={18} />
        </PrimaryButton>
        </View>
      )}
    </View>
  );
}
