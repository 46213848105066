import React, { createContext, useEffect, useState } from "react";

export const ConfigContext = createContext();

export function ConfigProvider({ children }) {

  const [darkMode, setDarkMode] = useState(false);

  const [update, setUpdate] = useState(false);

  const [dashboardTab, setDashboardTab] = useState("Links");

  const [selectedIds, setSelectedIds] = useState([]);

  const [prefixLink, setPrefixLink] = useState("");

  const [appearanceSettings, setAppearanceSettings] = useState(null);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('darkMode');
    } else {
      document.documentElement.classList.remove('darkMode');
    }
  }, [darkMode]);

  return (
    <ConfigContext.Provider value={{ appearanceSettings, setAppearanceSettings, prefixLink, setPrefixLink, selectedIds, setSelectedIds, darkMode, setDarkMode, update, setUpdate, dashboardTab, setDashboardTab }}>
      {children}
    </ConfigContext.Provider>
  );
}
