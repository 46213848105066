import React, { useContext, useEffect, useRef, useState } from "react";
import SecondaryButton from "../buttons/SecondaryButton";
import Link from "../links/Link";
import {
  ArrowRight,
  Link as DoLink,
  Edit2,
  Layout,
  LogOut,
  PieChart,
  Shield,
  User,
} from "react-feather";
import View from "../view/View";
import styles from "./styles/Nav.module.css";
import MobileMenu from "./MobileMenu";
import Button from "../buttons/Button";
import Paragraph from "../text/paragraph/Paragraph";
import Spacer from "../view/impl/Spacer";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Link2, Search, Settings } from "react-feather";
import { ConfigContext } from "../../context/ConfigProvider";
import { EmailContext } from "../../context/EmailProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import ProfilePicture from "../view/impl/ProfilePicture";
import { StorageContext } from "../../context/StorageProvider";

export default function Nav(props) {
  const location = useLocation();

  const { darkMode, dashboardTab, setDashboardTab } = useContext(ConfigContext);

  const containerRef = useRef(null);

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const [showRightFader, setShowRightFader] = useState(true);
  const [showLeftFader, setShowLeftFader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const maxScrollWidth = container.scrollWidth - container.clientWidth;

      const onScroll = () => {
        const scrollLeft = container.scrollLeft;
        if (scrollLeft === 0) {
          setShowLeftFader(false);
        } else {
          setShowLeftFader(true);
        }
        if (scrollLeft >= maxScrollWidth) {
          setShowRightFader(false);
        } else {
          setShowRightFader(true);
        }
      };

      container.removeEventListener("scroll", onScroll);
      container.addEventListener("scroll", onScroll, { passive: true });

      return () => {
        container.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  if (location.pathname === "/user") {
    return (
      <header className={styles.container}>
        <View className={styles.buttonContainer}>
          <View className={styles.desktopButtonContainer}>
            <PrimaryButton
              size="medium"
              className={styles.mainNavButton}
              to="/join"
            >
              Create your own devpage
            </PrimaryButton>
            <SecondaryButton
              size="medium"
              className={`${styles.mainNavButton} ${styles.hide670}`}
              to="/login"
            >
              Log In
            </SecondaryButton>
          </View>
          <View className={styles.mobileButtonContainer}>
            <MobileMenu />
          </View>
        </View>
      </header>
    );
  }

  function setTab(tab) {
    setDashboardTab(tab);
  }

  function signOut() {
    setUserDetails(null);
    navigate("/");
  }

  if (
    location.pathname.includes("/dashboard/") ||
    location.pathname === "/dashboard"
  ) {
    return (
      <header className={styles.filledContainer}>
        <View className={styles.logoContainer}>
          <Link to="/">
            <img
              className={`${styles.icon}`}
              src={
                darkMode
                  ? "./assets/images/icon_white.png"
                  : "./assets/images/icon.png"
              }
              alt="devpage Logo"
            />
          </Link>

          <Spacer size="medium" />
          <div
            ref={containerRef}
            gap="small"
            className={styles.categoryButtonContainer}
          >
            <Button
              size="small"
              onClick={() => setTab("Links")}
              className={`${styles.categoryButton} ${
                dashboardTab === "Links" && styles.currentCategory
              }`}
            >
              <DoLink size="16" />
              Links
            </Button>
            <Button
              size="small"
              onClick={() => setTab("Appearance")}
              className={`${styles.categoryButton} ${
                dashboardTab === "Appearance" && styles.currentCategory
              }`}
            >
              <Layout size="16" />
              Appearance
            </Button>
            {
              userDetails && userDetails.type === 3 && (
                <Button
              size="small"
              onClick={() => setTab("Admin")}
              className={`${styles.categoryButton} ${
                dashboardTab === "Admin" && styles.currentCategory
              }`}
            >
              <Shield size="16" />
              Admin
            </Button>
              )
            }
            <Button
              size="small"
              onClick={() => setTab("Profile")}
              className={`${styles.categoryButton} ${
                dashboardTab === "Profile" && styles.currentCategory
              } ${styles.show670}`}
            >
              <User size="16" />
              Profile
            </Button>
            {userDetails && (
              <Button
                size="small"
                onClick={() => signOut()}
                className={`${styles.categoryButton} ${styles.signOutButton}`}
              >
                <LogOut size="16" />
                Sign Out
              </Button>
            )}
          </div>
          <View
            className={`${styles.leftFader} ${
              !showLeftFader && styles.hideFader
            }`}
          ></View>
          <View
            className={`${styles.rightFader} ${
              !showRightFader && styles.hideFader
            }`}
          ></View>
        </View>
        <View className={styles.buttonContainer}>
          <View className={`${styles.buttonContainer} ${styles.hide670}`}>
            {userDetails && (
              <ProfilePicture
                className={
                  dashboardTab === "Profile" && styles.currentCategoryProfile
                }
                onClick={() => setTab("Profile")}
                size="small"
                name={userDetails.name}
                url={userDetails.picture}
              />
            )}
          </View>
        </View>
      </header>
    );
  }
  if (
    props.render || 
    location.pathname === "/" ||
    location.pathname.includes("/brand") ||
    location.pathname.includes("/privacy-policy") ||
    location.pathname.includes("/cookie-policy") ||
    location.pathname.includes("/terms-of-service") ||
    location.pathname.includes("/login") ||
    location.pathname.includes("/signup") ||
    location.pathname.includes("/join")
  ) {
    return (
      <header className={styles.container}>
        <View className={styles.logoContainer}>
          <Link to="/">
            <img
              className={styles.logo}
              src={
                darkMode
                  ? "./assets/images/logo_white.png"
                  : "./assets/images/logo.png"
              }
              alt="devpage Logo"
            />
          </Link>
        </View>
        <View className={styles.buttonContainer}>
          <View className={styles.desktopButtonContainer}>
            {location.pathname !== "/join" && !userDetails && (
              <PrimaryButton
                size="medium"
                className={styles.mainNavButton}
                to="/join"
              >
                Get Started
              </PrimaryButton>
            )}
            {location.pathname !== "/login" && !userDetails && (
              <SecondaryButton
                size="medium"
                className={`${styles.mainNavButton} ${styles.hide670}`}
                to="/login"
              >
                Log In
              </SecondaryButton>
            )}
            {userDetails && (
              <Button
                size="medium"
                className={`${styles.mainNavButton} ${styles.iconButton}`}
                to="/dashboard"
              >
                Dashboard
                <ArrowRight size="16" />
              </Button>
            )}
          </View>
          <View className={styles.mobileButtonContainer}>
            <MobileMenu />
          </View>
        </View>
      </header>
    );
  }
}
