import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const EmailContext = createContext();

export function EmailProvider({ children }) {

  async function sendEmail({
    from,
    fromName,
    to,
    subject,
    template,
    templateParams,
  }) {
    const response = await axios
      .post("http://localhost:3001/api/send-email", {
        from,
        fromName,
        to,
        subject,
        template,
        templateParams,
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
    return response;
  }

  return (
    <EmailContext.Provider value={{ sendEmail }}>
      {children}
    </EmailContext.Provider>
  );
}
