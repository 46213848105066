import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { AlertCircle, ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";
import { LINK_EXISTS } from "../../graphql/queries/Users";
import PrimaryButton from "../buttons/PrimaryButton";
import PrefixTextInput from "../input/PrefixTextInput";
import H2 from "../text/heading/H2";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/CompanyLanding.module.css";

export default function CompanyLanding() {
  
  const [link, setLink] = useState("");

  const { setPrefixLink } = useContext(ConfigContext);

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const client = useApolloClient();

  useEffect(() => {
    document.title = 'Link in bio tool for software developers: The link of all links';
  }, []);

  async function createAccount() {
    const response = await client
      .query({
        query: LINK_EXISTS,
        variables: {
          link: link,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data) {
      const linkExists = response.data.linkExists;
      if (!linkExists) {
        setPrefixLink(link);
        navigate("/join");
      } else {
        setError({
          type: 1,
          message: "This link is already in use. Please try another.",
        });
      }
    }
  }

  function setLinkInput(link) {
    setError(null);
    setLink(link);
  }

  return (
    <View vertical>
      <View centerVertically centerHorizontally>
        <View className={styles.container}>
          <View vertical className={styles.wrapper}>
            <Spacer size="large" />
            <H2 className={styles.header}>
              Create the perfect first impression with devpage
            </H2>
            <Spacer size="large" />
            <Paragraph size="large" className={styles.message}>
              Linktree for software developers. Create your own landing page and
              highlight your gems.
              <b> No credit-card needed</b>.
            </Paragraph>
            <Spacer size="small" />
            <Spacer size="large" />
            <Spacer size="small" />
            <View gap="medium" className={styles.waitlistContainer}>
              <View vertical className={styles.autoWidth}>
                <PrefixTextInput
                  size="big"
                  value={link}
                  className={`${
                    error !== null && error.type === 1 && styles.ctaInputError
                  }`}
                  onChange={(e) => setLinkInput(e.target.value)}
                  prefixText="devpa.ge/"
                  placeholder="yourname"
                />
                {error !== null && error.type === 1 && (
                  <View
                    className={styles.errorContainer}
                    centerVertically
                    centerHorizontally
                  >
                    <AlertCircle
                      className={styles.errorIcon}
                      size="14"
                      color="var(--red)"
                    />
                    <Paragraph size="small" className={styles.errorMessage}>
                      {error.message}
                    </Paragraph>
                  </View>
                )}
              </View>

              <PrimaryButton
                size="big"
                onClick={createAccount}
                className={styles.ctaButton}
              >
                Claim link
                <ChevronRight color="var(--neutral-1000)" size={18} />
              </PrimaryButton>
            </View>
            <Spacer size="medium" />
            <Spacer size="medium" />
            <div className={styles.checkList}>
              <Paragraph size="small">Privacy-friendly</Paragraph>
              <Spacer size="small" />
              <Paragraph className={styles.separatorDot} size="small">
                •
              </Paragraph>
              <Spacer size="small" />
              <Paragraph size="small">No subscription required</Paragraph>
            </div>
          </View>
        </View>
      </View>
    </View>
  );
}
