import React from "react";
import styles from "./styles/TextInput.module.css";

const TextInput = React.forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      value={props.value}
      type={props.type || "text"}
      placeholder={props.placeholder}
      className={`${styles.defaults} ${props.className} ${
        props.size == "big"
          ? styles.big
          : props.size === "medium"
          ? styles.medium
          : props.size == "small"
          ? styles.small
          : ""
      }`}
      onChange={props.onChange}
    />
  );
});

export default TextInput;
