import { Tooltip } from "evergreen-ui";
import React, { useContext, useState } from "react";
import { Bookmark, Check } from "react-feather";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import ProfilePicture from "../../images/ProfilePicture";
import Alert from "../../text/alert/Alert";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/CreateSectionModal.module.css";
import TextInput from "../../input/TextInput";
import { useApolloClient } from "@apollo/client";
import { CREATE_SECTION } from "../../../graphql/mutations/Sections";
import { ConfigContext } from "../../../context/ConfigProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SectionContext } from "../../../context/SectionProvider";
import Spacer from "../../view/impl/Spacer";

export default function CreateSectionModal(props) {

  const { userDetails } = useContext(StorageContext);

  const { sectionData } = useContext(SectionContext);

  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  const [sectionName, setSectionName] = useState("");

  async function createSection() {
    await client
      .mutate({
        mutation: CREATE_SECTION,
        variables: {
          sectionId: -1,
          name: sectionName,
          owner: userDetails.email,
          archived: false
        },
      })
      .catch((error) => {
        console.error("Error creating session:", error);
      });
      setTimeout(() => {
        closeOverlay();
        setSectionName("");
        setUpdate(true);
        props.onUpdate();
      }, 500)
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Create new section</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          Please enter the name of your new section.
        </Paragraph>
        <Spacer size="small" />
        <TextInput
          size="small"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          className={styles.fullWidth}
          placeholder="Section name"
        />
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={createSection}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
