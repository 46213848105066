import { useApolloClient } from "@apollo/client";
import React, { useContext, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SectionContext } from "../../../context/SectionProvider";
import { StorageContext } from "../../../context/StorageProvider";
import PrimaryButton from "../../buttons/PrimaryButton";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/ConfirmDeletion.module.css";
import Spacer from "../../view/impl/Spacer";

export default function ConfirmDeletion(props) {
  const { userDetails } = useContext(StorageContext);

  const { sectionData } = useContext(SectionContext);

  const { closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  async function deleteSelection() {
    setTimeout(() => {
      closeOverlay();
      setUpdate(true);
      props.onUpdate();
    }, 500);
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Delete selected</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          If you want to delete the selected items, press confirm.
        </Paragraph>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={deleteSelection}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
