import React, { useRef } from "react";
import { Clipboard } from "react-feather";
import Button from "../buttons/Button";
import TextInput from "./TextInput";
import styles from "./styles/ComponentTextInput.module.css";
import WelcomeEmail from "../../emails/WelcomeEmail";
import { Tooltip } from "@chakra-ui/react";

export default function ComponentTextInput(props) {
  const btnRef = useRef();

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(props.value);
  };

  return (
    <Button
      ref={btnRef}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onClick={props.onClick}
      centerVertically
      type={props.type || "text"}
      className={`${styles.defaults} ${props.className} ${
        props.size == "big"
          ? styles.big
          : props.size == "small"
          ? styles.small
          : ""
      }`}
    >
      {props.component}
      <TextInput
        value={props.value}
        className={styles.prefixTextInput}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </Button>
  );
}
