import React, { useContext, useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import Button from "../buttons/Button";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import View from "../view/View";
import styles from "./styles/MobileMenu.module.css";
import navStyles from "./styles/Nav.module.css";
import { useLocation } from "react-router-dom";
import { ConfigContext } from "../../context/ConfigProvider";

export default function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const { darkMode } = useContext(ConfigContext);

  useEffect(() => {
    const lines = document.querySelectorAll(`.${styles.line}`);
    const menu = document.querySelectorAll(`.${styles.mobileMenu}`)[0];
    if (menuOpen) {
      lines.forEach((line) => line.classList.add(styles.animateNavLine));
      menu.classList.add(styles.openMobileNavMenu);
    } else {
      lines.forEach((line) => line.classList.remove(styles.animateNavLine));
      menu.classList.remove(styles.openMobileNavMenu);
    }
  }, [menuOpen]);

  const location = useLocation();

  return (
    <View className={styles.mobileButtonContainer}>
      <Button
        onClick={() => setMenuOpen(!menuOpen)}
        className={styles.mobileButton}
      >
        <View className={styles.line}></View>
        <View className={styles.line}></View>
      </Button>
      <View className={styles.mobileMenu}>
        <View className={styles.mobileMenuWrapper}>
          <View className={`${navStyles.logoContainer} ${navStyles.fixedLogo}`}>
            <Button
              className={styles.cleanButton}
              onClick={() => setMenuOpen(false)}
              to="/"
            >
              <img
                className={navStyles.logo}
                src={darkMode ? "./assets/images/logo.png" : "./assets/images/logo_white.png"}
                alt="devpage Logo"
              />
            </Button>
          </View>
          <Button
            onClick={() => setMenuOpen(!menuOpen)}
            className={`${styles.mobileButton} ${styles.fixedMobileButton}`}
          >
            <View className={styles.line}></View>
            <View className={styles.line}></View>
          </Button>
          { location.pathname === "/user" ? (
            <View className={styles.mobileNavButtonContainer}>
              <PrimaryButton
                size="small"
                onClick={() => setMenuOpen(false)}
                bright
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/login"
              >
                Sign up for free
              </PrimaryButton>
              <View centerHorizontally centerVertically>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/"
                >
                  What's devpage?
                </PrimaryButton>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/for-business"
                >
                  Log In
                </PrimaryButton>
              </View>
            </View>
          ) : (
            <View className={styles.mobileNavButtonContainer}>
              <SecondaryButton
                size="small"
                onClick={() => setMenuOpen(false)}
                bright
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/login"
              >
                Sign In
              </SecondaryButton>
              <PrimaryButton
                size="small"
                onClick={() => setMenuOpen(false)}
                bright
                linkClassName={styles.maxWidthButton}
                className={styles.maxWidthButton}
                to="/join"
              >
                Get Started
              </PrimaryButton>
              <View centerHorizontally centerVertically>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/join"
                >
                  For developers
                  <ChevronRight color="var(--neutral-1000)" size={18} />
                </PrimaryButton>
                <PrimaryButton
                  size="small"
                  onClick={() => setMenuOpen(false)}
                  linkClassName={styles.maxWidthButton}
                  className={styles.ctaButton}
                  to="/join"
                >
                  For agencies
                  <ChevronRight color="var(--neutral-1000)" size={18} />
                </PrimaryButton>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
