import { gql } from "@apollo/client";

export const CREATE_SECTION = gql`
  mutation addSection(
    $sectionId: Int!
    $name: String!
    $owner: String!
    $archived: Boolean!
  ) {
    addSection(
      input: {
        sectionId: $sectionId
        name: $name
        owner: $owner
        archived: $archived
      }
    ) {
      sectionId
      name
      owner
      archived
    }
  }
`;

export const UPDATE_SECTIONS = gql`
  mutation UpdateSections(
    $sectionIds: String!
    $owner: String!
    $archived: Boolean!
  ) {
    updateSections(
      sectionIds: $sectionIds
      owner: $owner
      archived: $archived
    ) {
      sectionId
      name
      owner
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;

export const UPDATE_SECTIONS_VISIBILITY = gql`
  mutation UpdateSectionsVisibility(
    $sectionIds: String!
    $owner: String!
    $visible: Boolean!
  ) {
    updateSectionsVisibility(
      sectionIds: $sectionIds
      owner: $owner
      visible: $visible
    ) {
      sectionId
      name
      owner
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;

export const RENAME_SECTION = gql`
  mutation updateSection($sectionId: Int!, $name: String!) {
    updateSection(input: { sectionId: $sectionId, name: $name }) {
      sectionId
      name
      owner
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;

export const REORDER_SECTIONS = gql`
  mutation updateSectionsOrder($sectionIds: String!, $owner: String!, $direction: String!, $index: Int!, $targetIndex: Int!) {
    updateSectionsOrder(sectionIds: $sectionIds, owner: $owner, direction: $direction, index: $index, targetIndex: $targetIndex) {
      sectionId
      name
      owner
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;
