import { gql } from "@apollo/client";

export const ADD_LINK = gql`
  mutation addLink(
    $linkId: Int!
    $sectionId: Int!
    $uri: String!
    $owner: String!
    $slug: String!
    $archived: Boolean!
    $visible: Boolean!
    $description: String!
    $thumbnail: String!
  ) {
    addLink(
      input: {
        linkId: $linkId
        sectionId: $sectionId
        uri: $uri
        owner: $owner
        slug: $slug
        archived: $archived
        visible: $visible
        description: $description
        thumbnail: $thumbnail
      }
    ) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;

export const UPDATE_LINKS = gql`
  mutation UpdateLinks(
    $linkIds: String!
    $owner: String!
    $archived: Boolean!
  ) {
    updateLinks(linkIds: $linkIds, owner: $owner, archived: $archived) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;

export const UPDATE_LINK_URI_AND_SLUG = gql`
  mutation updateLinkUri(
    $linkId: Int!
    $uri: String!
    $slug: String!
    $description: String!
    $thumbnail: String!
  ) {
    updateLinkUri(
      input: {
        linkId: $linkId
        uri: $uri
        slug: $slug
        description: $description
        thumbnail: $thumbnail
      }
    ) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;

export const UPDATE_LINK_VISIBILITY = gql`
  mutation updateLinkUri($linkId: Int!, $visible: Boolean!) {
    updateLinkUri(input: { linkId: $linkId, visible: $visible }) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;

export const REORDER_LINKS = gql`
  mutation updateLinksOrder($linkIds: String!, $sectionId: Int!, $owner: String!) {
    updateLinksOrder(linkIds: $linkIds, sectionId: $sectionId, owner: $owner) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;
