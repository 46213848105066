import { gql } from "@apollo/client";

export const GET_SECTION_BY_SECTIONID = gql`
  query getSectionBySectionId($sectionId: Int!) {
    getSectionBySectionId(sectionId: $sectionId) {
      sectionId
      name
      owner
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;

export const GET_SECTIONS_BY_OWNER = gql`
  query getSectionsByOwner($owner: String!) {
    getSectionsByOwner(owner: $owner) {
      sectionId
      name
      links {
        linkId
        sectionId
        uri
        owner
        slug
        archived
        visible
        description
        thumbnail
      }
      archived
      visible
    }
  }
`;
