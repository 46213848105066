import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation addUser(
    $name: String!
    $email: String!
    $type: Int!
    $verification: Int!
    $link: String!
    $appearanceSettings: String!
  ) {
    addUser(
      input: {
        name: $name
        email: $email
        type: $type
        verification: $verification
        link: $link
        appearanceSettings: $appearanceSettings
      }
    ) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($email: String!, $hashed: String!, $hash: String!) {
    updateUser(input: { email: $email, hashed: $hashed, hash: $hash }) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($email: String!, $name: String!, $link: String!, $bio: String!, $location: String!, $picture: String!) {
    updateUser(input: { email: $email, name: $name, link: $link, bio: $bio, location: $location, picture: $picture}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const PUBLISH_SECTIONS = gql`
  mutation UpdateUser($email: String!, $published: String!) {
    updateUser(input: { email: $email, published: $published}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const VERIFY = gql`
  mutation UpdateUser($email: String!, $verification: Int!) {
    updateUser(input: { email: $email, verification: $verification}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const SET_USER_PRIVACY = gql`
  mutation UpdateUser($email: String!, $private: Boolean!) {
    updateUser(input: { email: $email, private: $private}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const SOFT_DELETE = gql`
  mutation UpdateUser($email: String!, $archived: Boolean!) {
    updateUser(input: { email: $email, archived: $archived}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const UPDATE_APPEARANCE_SETTINGS = gql`
  mutation UpdateUser($email: String!, $appearanceSettings: String!) {
    updateUser(input: { email: $email, appearanceSettings: $appearanceSettings}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;

export const PUBLISH_APPEARANCE = gql`
  mutation UpdateUser($email: String!, $publishedAppearance: String!) {
    updateUser(input: { email: $email, publishedAppearance: $publishedAppearance}) {
      name
      email
      type
      wants
      hashed
      hash
      verification
      link
      bio
      location
      picture
      published
      appearanceSettings
      private
      publishedAppearance
      archived
    }
  }
`;
