import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./styles/Button.module.css";
import { Oval } from "react-loader-spinner";
import View from "../view/View";
import { ConfigContext } from "../../context/ConfigProvider";
import { Tooltip } from "@chakra-ui/react";
import Link from "../links/Link";

const Button = React.forwardRef((props, ref) => {

  const { update, setUpdate } = useContext(ConfigContext);

  const [loading, setLoading] = useState(false);

  const [buttonSize, setButtonSize] = useState(null);

  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      const { width, height } = buttonRef.current.getBoundingClientRect();
      setButtonSize({ width, height });
    }
  }, []);

  useEffect(() => {
    if (update) {
      setLoading(false);
      setUpdate(false);
    }
  }, [update]);

  function onClick() {
    if (props.spinner) {
      setLoading(true);
    }
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <Tooltip className={styles.buttonTooltip} label={props.tooltip}>
      {
        props.to ? (
          <Link
        className={`${props.linkClassName} ${
          props.disabled ? styles.linkDisabled : ""
        }`}
        to={props.to}
      >
        <div
          className={
            props.spinner &&
            `${styles.containerDefaults} ${props.className} ${
              props.size === "big"
                ? styles.big
                : props.size === "medium"
                ? styles.medium
                : props.size === "small"
                ? styles.small
                : ""
            }
          ${props.disabled ? styles.disabled : ""}`
          }
        >
          <button
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            style={props.style}
            ref={ref || buttonRef}
            className={`${styles.defaults} ${props.className} ${
              props.size === "big"
                ? styles.big
                : props.size === "medium"
                ? styles.medium
                : props.size === "small"
                ? styles.small
                : ""
            }
          ${props.disabled ? styles.disabled : ""}`}
            disabled={props.disabled}
            onClick={onClick}
          >
            {props.children}
          </button>
          {props.spinner && loading && (
            <View
              className={styles.spinnerOverlay}
              centerVertically
              centerHorizontally
            >
              <Oval
                height={!buttonSize ? "24" : buttonSize.height / 3}
                width={!buttonSize ? "24" : buttonSize.height / 3}
                color="var(--neutral-400)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </View>
          )}
        </div>
      </Link>
        ) : (
        <div
          className={
            props.spinner &&
            `${styles.containerDefaults} ${props.className} ${
              props.size === "big"
                ? styles.bigContainer
                : props.size === "medium"
                ? styles.mediumContainer
                : props.size === "small"
                ? styles.smallContainer
                : ""
            }
          ${props.disabled ? styles.disabled : ""}`
          }
        >
          <button
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            style={props.style}
            ref={ref || buttonRef}
            className={`${styles.defaults} ${props.className} ${
              props.size === "big"
                ? styles.big
                : props.size === "medium"
                ? styles.medium
                : props.size === "small"
                ? styles.small
                : ""
            }
          ${props.disabled ? styles.disabled : ""}`}
            disabled={props.disabled}
            onClick={onClick}
          >
            {props.children}
          </button>
          {props.spinner && loading && (
            <View
              className={styles.spinnerOverlay}
              centerVertically
              centerHorizontally
            >
              <Oval
                height={!buttonSize ? "24" : buttonSize.height / 3}
                width={!buttonSize ? "24" : buttonSize.height / 3}
                color="var(--neutral-400)"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="var(--neutral-800)"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </View>
          )}
        </div>
        )
      }
    </Tooltip>
  );
});

export default Button;