import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertProvider";
import { ConfigContext } from "../../context/ConfigProvider";
import { RadioCellContext } from "../../context/RadioCellProvider";
import { ADD_USER } from "../../graphql/mutations/Users";
import Container from "../navigation/Container";
import RadioCellContainer from "../radiocell/RadioCellContainer";
import View from "../view/View";
import styles from "./styles/Join.module.css";
import { LINK_EXISTS } from "../../graphql/queries/Users";

export default function Join(props) {
  const { cache, setCache } = useContext(RadioCellContext);

  const client = useApolloClient();

  const { showAlert } = useContext(AlertContext);

  const { setUpdate, prefixLink } = useContext(ConfigContext);

  const [joinError, setJoinError] = useState(null);

  const [joinWarning, setJoinWarning] = useState(null);

  useEffect(() => {
    document.title = 'Claim your devpa.ge link – Join now';
  }, []);

  const [data, setData] = useState({
    finalMessage: "Welcome to Devpage!",
    questions: [
      {
        id: 1,
        questionType: 2,
        questionTitle: "What should we call you? 🤔",
        questionSubtitle: "We'll use this to personalize your experience.",
        question: "What is your name?",
        inputType: "text",
        inputPlaceholder: "John Doe",
        checkbox: true,
        checkboxRequired: true,
      },
      {
        id: 2,
        questionType: 2,
        questionTitle: "No spam, we promise. 🫶",
        questionSubtitle:
          "We will only use this to send you updates about your account and/or profile.",
        question: "What is your email address?",
        inputType: "email",
        inputPlaceholder: "john@doe.com",
      },
    ],
  });

  function makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  async function createAccount() {
    let handle = prefixLink;
    let response;
    if (prefixLink) {
      response = await client
        .query({
          query: LINK_EXISTS,
          variables: {
            link: prefixLink,
          },
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          setError({
            type: 1,
            message: error.message,
          });
        });
      if (response && response.data) {
        const linkExists = response.data.linkExists;
        if (linkExists) {
          handle = makeid(8);
        }
      }
    } else {
      handle = makeid(8);
    }
    response = await client
      .query({
        query: LINK_EXISTS,
        variables: {
          link: handle,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data) {
      const linkExists = response.data.linkExists;
      if (linkExists) {
        handle = makeid(8);
      }
    }
    const defaultAppearanceSettings = {
      backgroundType: 2,
      buttonType: 2,
      backgroundColor: "#0e0e0e",
      gradientColor1: "#393939",
      gradientColor2: "#0e0e0e",
      backgroundURL: null,
      buttonBorderRadius: "8",
      buttonColor: "#000",
      buttonBorderColor: "#fff",
      buttonBackgroundColor: "#fff",
      nameColor: "#fff",
      bioColor: "#c3c3c3",
      locationColor: "#c3c3c3",
      sectionColor: "#fff",
    };
    client
      .mutate({
        mutation: ADD_USER,
        variables: {
          name: cache.userName,
          email: cache.userEmail,
          type: cache.userEmail === "julius@nineteenmediagroup.com" ? 3 : 1,
          verification:
            cache.userEmail === "julius@nineteenmediagroup.com" ? 1 : 0,
          link: handle,
          appearanceSettings: JSON.stringify(defaultAppearanceSettings),
        },
      })
      .then((result) => {
        setCache(null);
        setJoinWarning("Thank you for creating an account.");
        setJoinError(null);
        return result;
      })
      .catch((error) => {
        switch (error.message) {
          case "already_exists":
            setCache(null);
            setUpdate(true);
            setJoinWarning("Your account already exists.");
            break;

          default:
            setCache(null);
            setUpdate(true);
            setJoinError("Something went wrong. Please try again.");
            console.error(error.message);
            break;
        }
      });
  }

  function handleCallback(questionIndex, selected, currentInput, selectedList) {
    switch (questionIndex) {
      case 1:
        setCache((prevCache) => {
          const updatedCache = { ...prevCache, userName: currentInput };
          return updatedCache;
        });
        break;

      case 2:
        setCache((prevCache) => {
          const updatedCache = { ...prevCache, userEmail: currentInput };
          return updatedCache;
        });
        break;
    }
  }

  useEffect(() => {
    if (cache && cache.userName && cache.userEmail) {
      createAccount();
    }
  }, [cache]);

  return (
    <Container className={styles.container}>
      <View centerVertically className={styles.loginContainer}>
        <View centerVertically className={styles.loginWrapper}>
          <RadioCellContainer
            error={joinError}
            warning={joinWarning}
            onCallback={handleCallback}
            data={data}
          />
        </View>
      </View>
    </Container>
  );
}
