import { gql } from "@apollo/client";

export const GET_LINK_BY_LINKID = gql`
  query getLinkByLinkId($linkId: Int!) {
    getLinkByLinkId(linkId: $linkId) {
      linkId
      sectionId
      uri
      owner
      slug
      archived
      visible
      description
      thumbnail
    }
  }
`;
