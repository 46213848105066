import { useApolloClient } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AlertCircle, Inbox } from "react-feather";
import { ConfigContext } from "../../context/ConfigProvider";
import { EmailContext } from "../../context/EmailProvider";
import {
  GET_USER_BY_EMAIL,
  VERIFY_GOOGLE_AUTH,
} from "../../graphql/queries/Users";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Container from "../navigation/Container";
import RadioButton from "../radiobutton/RadioButton";
import radioButtonStyles from "../radiobutton/styles/RadioButton.module.css";
import H5 from "../text/heading/H5";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import styles from "./styles/Login.module.css";
import { GoogleLogin } from "@react-oauth/google";
import { StorageContext } from "../../context/StorageProvider";
import { useNavigate } from "react-router-dom";
import Spacer from "../view/impl/Spacer";
import Nav from "../navigation/Nav";

export default function NotFound(props) {
  const { setUserDetails } = useContext(StorageContext);

  const { sendEmail } = useContext(EmailContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const textInputRef = useRef(null);

  const [linkSent, setLinkSent] = useState(false);

  const navigate = useNavigate();

  const { setPrefixLink } = useContext(ConfigContext);

  function claimPage() {
    setPrefixLink(props.link);
    navigate("/join");
  }

  useEffect(() => {
    document.title = "Oops! Looks like you're lost.";
  }, []);

  return (
    <Container className={styles.container}>
      <Nav render />
      <View className={styles.loginContainer}>
        <H5>{props.link ? "We couldn't find that page 🤷‍♂️" : "This page is private"}</H5>
        <Spacer size="large" />
        {
          props.link ? (
            <PrimaryButton onClick={claimPage} size="medium">
          Claim it now (no credit-card needed)
        </PrimaryButton>
          ) : (
            <PrimaryButton to="/" size="medium">
          Return home
        </PrimaryButton>
          )
        }
      </View>
    </Container>
  );
}
