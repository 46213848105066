import React from "react";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";
import styles from "./styles/RadioCell.module.css";
import {
  Briefcase,
  Check,
  Code,
  Layers,
  Smartphone,
  Tool,
} from "react-feather";
import Button from "../buttons/Button";

export default function RadioCell(props) {
  function Icon({ icon }) {
    switch (icon) {
      case "code":
        return <Code color="var(--neutral-400)" size="18" />;

      case "briefcase":
        return <Briefcase color="var(--neutral-400)" size="18" />;

      case "wrench":
        return <Tool color="var(--neutral-400)" size="18" />;

      case "smartphone":
        return <Smartphone color="var(--neutral-400)" size="18" />;

      case "layers":
        return <Layers color="var(--neutral-400)" size="18" />;
    }
  }
  return (
    <Button
      onClick={props.onClick}
      className={`${
        props.rows === 3 ? styles.buttonWrapper3 : styles.buttonWrapper
      }`}
    >
      <View
        gap="small"
        vertical
        centerVertically
        className={`${props.rows === 3 ? styles.defaults3 : styles.defaults} ${
          props.selected && styles.selected
        } ${props.transparentBackground && styles.transparentBackground}`}
      >
        <View
          className={`${styles.checkbox} ${
            props.selected && styles.filledCheckbox
          }`}
        >
          {props.selected && <Check color="var(--neutral-1000)" size="16" />}
        </View>
        {props.component ? (
          props.component
        ) : (
          <View className={styles.iconContainer}>
            <Icon icon={props.icon} />
          </View>
        )}
        {props.title && props.subtitle && <Spacer size="small" />}
        {props.title ||
          (props.subtitle && (
            <View vertical>
              {props.title && (
                <H6 className={props.titleSize === 14 && styles.smallTitle}>
                  {props.title}
                </H6>
              )}
              {props.subtitle && (
                <View className={styles.swampCore}>
                  {props.subtitle && <Spacer size="small" />}
                  {props.subtitle && (
                    <Paragraph className={styles.paragraph} size="small">
                      {props.subtitle}
                    </Paragraph>
                  )}
                </View>
              )}
            </View>
          ))}
      </View>
    </Button>
  );
}
