import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { UsePositionReorder } from "./UsePositionReorder";
import { UseMeasurePosition } from "./UseMeasurePosition";
import Paragraph from "../../text/paragraph/Paragraph";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Tooltip,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import RadioButton from "../../radiobutton/RadioButton";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import {
  Archive,
  ExternalLink,
  Eye,
  EyeOff,
  Menu,
  MoreVertical,
  Plus,
} from "react-feather";
import H6 from "../../text/heading/H6";
import Button from "../../buttons/Button";
import PrimaryButton from "../../buttons/PrimaryButton";
import Link from "../../links/Link";
import { isMobile } from "react-device-detect";
import { ConfigContext } from "../../../context/ConfigProvider";
import SecondaryButton from "../../buttons/SecondaryButton";
import { SectionContext } from "../../../context/SectionProvider";
import { useApolloClient } from "@apollo/client";
import { REORDER_SECTIONS } from "../../../graphql/mutations/Sections";
import { StorageContext } from "../../../context/StorageProvider";
import { REORDER_LINKS } from "../../../graphql/mutations/Links";

export default function LinkList(props) {
  const client = useApolloClient();

  const { userDetails } = useContext(StorageContext);

  const { darkMode, selectedIds, setSelectedIds } = useContext(ConfigContext);

  const [linkData, setLinkData] = useState(props.links);

  async function reorder(index, targetIndex) {
    const newSelectedIds = [...selectedIds];

    const indexId = `link: ${linkData[index].linkId}`;
    const targetIndexId = `link: ${linkData[targetIndex].linkId}`;

    // Reorder the radiobutton states.
    if (selectedIds.includes(indexId)) {
      const selectedIndex = selectedIds.indexOf(indexId);
      newSelectedIds[selectedIndex] = targetIndexId;
    }
    if (selectedIds.includes(targetIndexId)) {
      const selectedIndex = selectedIds.indexOf(targetIndexId);
      newSelectedIds[selectedIndex] = indexId;
    }

    setSelectedIds(newSelectedIds);

    const newLinkData = [...linkData];
    [newLinkData[index], newLinkData[targetIndex]] = [
      newLinkData[targetIndex],
      newLinkData[index],
    ];
    testo(newLinkData);
    setLinkData(newLinkData);
  }

  function testo(newItemsz) {
    console.log("run!");
    async function test() {
      console.log(
        "mutate now:",
        newItemsz.map((link) => link.linkId).toString()
      );
      const response = await client
        .mutate({
          mutation: REORDER_LINKS,
          variables: {
            linkIds: newItemsz.map((link) => link.linkId).toString(),
            sectionId: props.sectionId,
            owner: userDetails.email,
          },
        })
        .catch((error) => {
          console.error("Error adding link:", error);
        });
      console.log("response is:", response);
      props.getSections();
    }
    test();
  }

  const [updatedList, updatePosition, updateOrder] = UsePositionReorder(
    reorder,
    linkData
  );

  return (
    <Accordion
      allowMultiple
      defaultIndex={selectedIds}
      className={styles.accordion}
    >
      <ul className="container">
        {updatedList &&
          updatedList.map((link, index) => (
            <Item
              openEditLinkModal={props.openEditLinkModal}
              openLinkModal={props.openLinkModal}
              setSectionVisibility={props.setSectionVisibility}
              setLinkVisibility={props.setLinkVisibility}
              openEditSectionModal={props.openEditSectionModal}
              handleToggle={props.handleToggle}
              darkMode={darkMode}
              link={link}
              selectedIds={selectedIds}
              key={link.linkId}
              index={index}
              updateOrder={updateOrder}
              updatePosition={updatePosition}
              slug={link.slug}
            />
          ))}
      </ul>
    </Accordion>
  );
}

function Item({
  openEditLinkModal,
  openLinkModal,
  setSectionVisibility,
  setLinkVisibility,
  openEditSectionModal,
  handleToggle,
  darkMode,
  link,
  slug,
  updateOrder,
  updatePosition,
  index,
}) {
  const { selectedIds, setSelectedIds } = useContext(ConfigContext);

  const [isdragged, setIsDragged] = React.useState(false);

  const itemRef = UseMeasurePosition((pos) => updatePosition(index, pos));

  return (
    <li
      className={styles.fiftyTwo}
      style={{
        position: "relative",
      }}
    >
      <motion.div
        style={{
          zIndex: isdragged ? 20 : 1,
        }}
        dragConstraints={{
          top: 0,
          bottom: 0,
        }}
        animate={{
          position: "relative",
        }}
        dragElastic={1}
        layout
        ref={itemRef}
        onDragStart={() => setIsDragged(true)}
        onDragEnd={() => setIsDragged(false)}
        onViewportBoxUpdate={(_, delta) => {
          isdragged && updateOrder(index, delta.y.translate);
        }}
        drag="y"
      >
        <View centerVertically className={styles.linkCell}>
          <RadioButton
            active={selectedIds.includes("link: " + link.linkId)}
            onClick={() => handleToggle("link: " + link.linkId)}
            className={styles.smallRadioButton}
          />
          <Menu
            className={styles.smallMenu}
            color="var(--neutral-600)"
            size="14"
          />
          <Spacer size="medium" />
          {link.thumbnail && (
            <img
              className={`${styles.thumbnail}`}
              src={link.thumbnail}
              alt={"Thumbnail"}
            />
          )}
          <Paragraph className={styles.userLink}>{link.slug}</Paragraph>
          <Spacer size="small" />
          <Link target="_blank" to={link.uri}>
            <Tooltip className={styles.tooltip} label={link.uri}>
              <ExternalLink size="14" />
            </Tooltip>
          </Link>
          <Spacer size="medium" />
          <View className={styles.rightContainer}>
            <Button
              tooltip={link.visible ? "Hide link" : "Show link"}
              onClick={() => setLinkVisibility(link.linkId, link.visible)}
              className={styles.iconLink}
              size="small"
            >
              {link.visible ? (
                <Eye color="var(--neutral-100)" size="15" />
              ) : (
                <EyeOff color="var(--neutral-100)" size="15" />
              )}
            </Button>
            <Button
              tooltip="Edit link"
              onClick={() => openEditLinkModal(link.linkId)}
              className={styles.iconLink}
              size="small"
            >
              <MoreVertical color="var(--neutral-100)" size="16" />
            </Button>
          </View>
        </View>
      </motion.div>
    </li>
  );
}
