import React from "react";
import styles from "./styles/PrefixTextInput.module.css";
import View from "../view/View";
import TextInput from "./TextInput";
import Paragraph from "../text/paragraph/Paragraph";

export default function PrefixTextInput(props) {
  return (
    <View
      centerVertically
      type={props.type || "text"}
      className={`${styles.defaults} ${props.className} ${
        props.size == "big"
          ? styles.big
          : props.size == "small"
          ? styles.small
          : ""
      }`}
    >
      <Paragraph className={`${styles.prefixText} ${props.size == "big"
            ? styles.bigPrefixText
            : props.size == "small"
            ? styles.smallPrefixText
            : ""}`}>{props.prefixText}</Paragraph>
      <TextInput
        value={props.value}
        size={props.size}
        className={`${styles.prefixTextInput} ${
          props.size == "big"
            ? styles.bigPrefixTextInput
            : props.size == "small"
            ? styles.smallPrefixTextInput
            : ""
        }`}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </View>
  );
}
