import React from "react";
import styles from "./styles/Link.module.css";
import { Link as RouterLink } from 'react-router-dom';

export default function Link(props) {
  return (
    <RouterLink style={props.style} target={props.target} disabled={props.disabled} to={props.to} className={`${styles.defaults} ${props.className} ${props.underlined && styles.underlined} ${props.size == 'small' && styles.small} ${props.size == 'big' && styles.big}`}>
      {props.children}
    </RouterLink>
  );
}
