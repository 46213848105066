import { ChakraProvider } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useContext, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/navigation/Footer";
import Nav from "./components/navigation/Nav";
import Auth from "./components/pages/Auth";
import CompanyLanding from "./components/pages/CompanyLanding";
import Dashboard from "./components/pages/Dashboard";
import Join from "./components/pages/Join";
import Login from "./components/pages/Login";
import Policy from "./components/pages/Policy";
import StyleGuide from "./components/pages/StyleGuide";
import User from "./components/pages/User";
import { AlertProvider } from "./context/AlertProvider";
import { ConfigContext } from "./context/ConfigProvider";
import { EmailProvider } from "./context/EmailProvider";
import { OverlayProvider } from "./context/OverlayProvider";
import { RadioCellProvider } from "./context/RadioCellProvider";
import { SectionProvider } from "./context/SectionProvider";
import CookieBanner from "./components/cookies/CookieBanner";
import { StorageContext } from "./context/StorageProvider";

export default function Main() {
  const { setDarkMode } = useContext(ConfigContext);

  const emotionCache = createCache({
    key: "emotion-css-cache",
    prepend: true, // ensures styles are prepended to the <head>, instead of appended
  });

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    // Check the initial value
    setDarkMode(darkModeMediaQuery.matches);

    // Update the value when the preference changes
    const handleChange = (event) => {
      setDarkMode(event.matches);
    };
    darkModeMediaQuery.addEventListener("change", handleChange);

    // Clean up the event listener when the component unmounts
    return () => {
      darkModeMediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  return (
    <Router>
      <CacheProvider value={emotionCache}>
        <GoogleOAuthProvider clientId="229785060803-s6emubaap12dmh1ho1sv25tp8gj2gjok.apps.googleusercontent.com">
          <ChakraProvider>
            <EmailProvider>
              <AlertProvider>
                <SectionProvider>
                  <RadioCellProvider>
                    <OverlayProvider>
                      <AppContent />
                    </OverlayProvider>
                  </RadioCellProvider>
                </SectionProvider>
              </AlertProvider>
            </EmailProvider>
          </ChakraProvider>
        </GoogleOAuthProvider>
      </CacheProvider>
    </Router>
  );
}

function AppContent() {

  const { acceptedCookies, hideCookieBanner } = useContext(StorageContext);

  const { darkMode } = useContext(ConfigContext);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (darkMode) {
      link.href = 'https://devpa.ge/assets/images/favicon_white.png';
    }
  }, []);
  
  return (
    <div className="body">
      <Nav />
      {((!acceptedCookies || !acceptedCookies.closed) && !hideCookieBanner) && <CookieBanner />}
      <Routes>
        <Route exact path="/" element={<CompanyLanding />} />
        <Route exact path="/for-business" element={<CompanyLanding />} />
        <Route path="/user" element={<User />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/brand" element={<StyleGuide />} />
        <Route path="/auth/:id" element={<Auth />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<Policy policy="privacy" />} />
        <Route path="/cookie-policy" element={<Policy policy="cookie" />} />
        <Route path="/terms-of-service" element={<Policy policy="tos" />} />
        <Route path="/signup" element={<Login signup />} />
        <Route path="/join" element={<Join />} />
        <Route path="/:link" element={<User />} />
      </Routes>
      <Footer />
    </div>
  );
}
