import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { UsePositionReorder } from "./UsePositionReorder";
import { UseMeasurePosition } from "./UseMeasurePosition";
import Paragraph from "../../text/paragraph/Paragraph";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Tooltip,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import RadioButton from "../../radiobutton/RadioButton";
import View from "../../view/View";
import Spacer from "../../view/impl/Spacer";
import {
  Archive,
  ExternalLink,
  Eye,
  EyeOff,
  Menu,
  MoreVertical,
  Plus,
} from "react-feather";
import H6 from "../../text/heading/H6";
import Button from "../../buttons/Button";
import PrimaryButton from "../../buttons/PrimaryButton";
import Link from "../../links/Link";
import { isMobile } from "react-device-detect";
import { ConfigContext } from "../../../context/ConfigProvider";
import SecondaryButton from "../../buttons/SecondaryButton";
import { SectionContext } from "../../../context/SectionProvider";
import { useApolloClient } from "@apollo/client";
import { REORDER_SECTIONS } from "../../../graphql/mutations/Sections";
import { StorageContext } from "../../../context/StorageProvider";
import LinkList from "./LinkList";

export default function SectionList(props) {
  const client = useApolloClient();

  const { userDetails } = useContext(StorageContext);

  const { darkMode, selectedIds, setSelectedIds } = useContext(ConfigContext);

  const { sectionData, setSectionData } = useContext(SectionContext);

  async function reorder(index, targetIndex) {

    const newSelectedIds = [...selectedIds];

    const indexId = `section: ${sectionData[index].sectionId}`;
    const targetIndexId = `section: ${sectionData[targetIndex].sectionId}`;

    // Reorder the radiobutton states.
    if (selectedIds.includes(indexId)) {
      const selectedIndex = selectedIds.indexOf(indexId);
      newSelectedIds[selectedIndex] = targetIndexId;
    }
    if (selectedIds.includes(targetIndexId)) {
      const selectedIndex = selectedIds.indexOf(targetIndexId);
      newSelectedIds[selectedIndex] = indexId;
    }

    // Reorder the accordion open/close states.
    if (selectedIds.includes(index)) {
      const selectedIndex = selectedIds.indexOf(index);
      newSelectedIds[selectedIndex] = targetIndex;
    }
    if (selectedIds.includes(targetIndex)) {
      const selectedIndex = selectedIds.indexOf(targetIndex);
      newSelectedIds[selectedIndex] = index;
    }

    setSelectedIds(newSelectedIds);

    const newSectionData = [...sectionData];
    [newSectionData[index], newSectionData[targetIndex]] = [
      newSectionData[targetIndex],
      newSectionData[index],
    ];
    testo(newSectionData, index < targetIndex ? "down" : index > targetIndex ? "up" : "neutral",
    index,
    targetIndex);
    setSectionData(newSectionData);
  }

  function testo(newItemsz, direction, index, targetIndex) {
    console.log("run!");
    async function test() {
      console.log(
        "mutate now:",
        newItemsz.map((section) => section.sectionId).toString()
      );
      const response = await client
        .mutate({
          mutation: REORDER_SECTIONS,
          variables: {
            sectionIds: newItemsz
              .map((section) => section.sectionId)
              .toString(),
            owner: userDetails.email,
            direction: direction,
            index,
            targetIndex,
          },
        })
        .catch((error) => {
          console.error("Error adding link:", error);
        });
      console.log("response is:", response);
      props.getSections();
    }
    test();
  }

  const [updatedList, updatePosition, updateOrder] = UsePositionReorder(
    reorder,
    props.sectionData
  );

  return (
    <Accordion
      allowMultiple
      defaultIndex={selectedIds}
      className={styles.accordion}
    >
      <ul className="container">
        {updatedList &&
          updatedList.map((section, index) => (
            <Item
              getSections={props.getSections}
              openEditLinkModal={props.openEditLinkModal}
              openLinkModal={props.openLinkModal}
              setSectionVisibility={props.setSectionVisibility}
              setLinkVisibility={props.setLinkVisibility}
              openEditSectionModal={props.openEditSectionModal}
              handleToggle={props.handleToggle}
              darkMode={darkMode}
              section={section}
              selectedIds={selectedIds}
              key={section.sectionId}
              index={index}
              updateOrder={updateOrder}
              updatePosition={updatePosition}
              name={section.name}
            />
          ))}
      </ul>
    </Accordion>
  );
}

function Item({
  getSections,
  openEditLinkModal,
  openLinkModal,
  setSectionVisibility,
  setLinkVisibility,
  openEditSectionModal,
  handleToggle,
  darkMode,
  section,
  name,
  updateOrder,
  updatePosition,
  index,
}) {
  const { selectedIds, setSelectedIds } = useContext(ConfigContext);

  const [isdragged, setIsDragged] = React.useState(false);

  const itemRef = UseMeasurePosition((pos) => updatePosition(index, pos));

  return (
      <motion.div
        style={{
          zIndex: isdragged ? 20 : 1,
        }}
        dragConstraints={{
          top: 0,
          bottom: 0,
        }}
        animate={{
          position: "relative",
        }}
        dragElastic={1}
        layout
        ref={itemRef}
        onDragStart={() => setIsDragged(true)}
        onDragEnd={() => setIsDragged(false)}
        onViewportBoxUpdate={(_, delta) => {
          isdragged && updateOrder(index, delta.y.translate);
        }}
        drag="y"
      >
        <AccordionItem className={`${styles.accordionItem}`}>
          <RadioButton
            active={selectedIds.includes("section: " + section.sectionId)}
            onClick={() => handleToggle("section: " + section.sectionId)}
            className={styles.categoryRadioButton}
          />
          <AccordionButton
            onClick={() => handleToggle(index)}
            className={`${styles.accordionButton} ${
              isMobile && styles.mobileAccordionButton
            }`}
          >
            <View centerVertically>
              <Spacer size="medium" />
              <Menu
                className={styles.sectionHandle}
                color="var(--neutral-600)"
                size="16"
              />
              <Spacer size="medium" />
              <H6 className={styles.categoryTitle}>{section.name}</H6>
              <Spacer size="medium" />
            </View>
            <AccordionIcon
              className={`${styles.accordionIcon} ${
                darkMode && styles.darkModeAccordionIcon
              }`}
            />
          </AccordionButton>
          <View
            centerVertically
            centerHorizontally
            className={`${styles.iconLink} ${styles.sectionIconLink}`}
          >
            <Button
              onClick={() => openEditSectionModal(section.sectionId)}
              tooltip="Edit section"
              size="small"
            >
              <MoreVertical color="var(--neutral-100)" size="19" />
            </Button>
          </View>
          <View
            centerVertically
            centerHorizontally
            className={`${styles.iconLink} ${styles.secondSectionIconLink}`}
          >
            <Button
              tooltip={
                section.links.length === 0 ? "Add a link to show this section" : section.links.filter((link) => link.visible !== false).length === 0 ? "Show a link to show this section" : section.visible !== false ? "Hide section" : "Show section"
              }
              disabled={section.links.length === 0 || section.links.filter((link) => link.visible !== false).length === 0}
              onClick={() =>
                section.links.length > 0 && section.links.filter((link) => link.visible !== false).length > 0 && setSectionVisibility(section.sectionId, section.visible)
              }
              size="small"
            >
              {section.visible !== false && section.links.length > 0 && section.links.filter((link) => link.visible !== false).length > 0 ? (
                <Eye color="var(--neutral-100)" size="15" />
              ) : (
                <EyeOff color="var(--neutral-100)" size="15" />
              )}
            </Button>
          </View>
          <AccordionPanel className={styles.accordionPanel} pb={4}>
            {(section.links === null || section.links.length === 0) && (
              <View
                pad="large"
                gap="medium"
                centerVertically
                centerHorizontally
                vertical
              >
                <Archive color="var(--neutral-100)" size="20" />
                <Paragraph size="small">This section is empty.</Paragraph>
                <PrimaryButton
                  onClick={() => openLinkModal(section.sectionId)}
                  className={styles.iconButton}
                  size="small"
                >
                  <Plus size="16" />
                  Add link
                </PrimaryButton>
              </View>
            )}
            {section.links !== null && (
              <LinkList
                openEditLinkModal={openEditLinkModal}
                sectionId={section.sectionId}
                getSections={getSections}
                setLinkVisibility={setLinkVisibility}
                links={section.links}
                handleToggle={handleToggle}
              />
            )}
            {section.links !== null && section.links.length > 0 && (
              <View
                pad="small"
                vertical
                centerVertically
                className={styles.difBg}
              >
                <View pad="small" centerVertically>
                  <Spacer size="small" />
                  <Paragraph className={styles.noWrap} size="small">
                    Showing {section.links.length}
                    /15 links
                  </Paragraph>
                  <View className={styles.rightContainer}>
                    <SecondaryButton
                      onClick={() => openLinkModal(section.sectionId)}
                      className={styles.iconButton}
                      size="small"
                    >
                      <Plus size="16" />
                      Add link
                    </SecondaryButton>
                  </View>
                </View>
              </View>
            )}
          </AccordionPanel>
        </AccordionItem>
      </motion.div>
  );
}
