import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Paragraph from "../text/paragraph/Paragraph";
import Link from "../links/Link";
import H6 from "../text/heading/H6";
import View from "../view/View";
import Spacer from "../view/impl/Spacer";

import styles from "./styles/Footer.module.css";
import { ConfigContext } from "../../context/ConfigProvider";
import PrimaryButton from "../buttons/PrimaryButton";
import { Twitter } from "react-feather";
import Button from "../buttons/Button";
import { Linkedin } from "react-feather";
import Modal from "../modals/Modal";
import CookiePreferencesModal from "../modals/impl/CookiePreferencesModal";
import { OverlayContext } from "../../context/OverlayProvider";

export default function Footer() {
  const location = useLocation();

  const { darkMode } = useContext(ConfigContext);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  const openCookiePreferencesModal = () => {
    openOverlay(
      <Modal title="Cookie Preferences">
        <CookiePreferencesModal />
      </Modal>
    );
  };

  if (
    location.pathname === "/" ||
    location.pathname.includes("/brand") ||
    location.pathname.includes("/privacy-policy") ||
    location.pathname.includes("/cookie-policy") ||
    location.pathname.includes("/terms-of-service") ||
    location.pathname.includes("/auth")
  ) {
    return (
      <footer className={styles.container}>
        <View gap="large" className={styles.wrapper}>
          <View vertical gap="small">
            <img
              className={styles.logo}
              src={
                darkMode
                  ? "./assets/images/logo_white.png"
                  : "./assets/images/logo.png"
              }
              alt="devpage Logo"
            />
            <Spacer size="small" />
            <Paragraph size="small">
              Linktree for software developers. Create your own landing page and
              highlight your gems.
            </Paragraph>
          </View>
        </View>
        <View gap="large" className={styles.wrapper}>
          <View className={styles.someWrapper}>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Account</H6>
                  <Link size="small" to="/join">
                    Get Started
                  </Link>
                  <Link size="small" to="/login">
                    Log In
                  </Link>
                </View>
              </View>
            </View>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Legal</H6>
                  <Link size="small" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  <Link size="small" to="/cookie-policy">
                    Cookie Policy
                  </Link>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    onClick={openCookiePreferencesModal}
                  >
                    Cookie Preferences
                  </Button>
                  <Link size="small" to="/terms-of-service">
                    Terms of Service
                  </Link>
                </View>
              </View>
            </View>
          </View>
          <View className={styles.someWrapper}>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical>
                  <H6 className={styles.miniH6}>Support</H6>
                  <Button
                    className={styles.noPadButton}
                    size="small"
                    onClick={() => {
                      window.location.href = "mailto:contact@tryluna.ai?subject=Regarding devpa.ge";
                    }}
                  >
                    Get in Touch
                  </Button>
                </View>
              </View>
            </View>
            <View centerHorizontally className={styles.thirdy}>
              <View>
                <View gap="medium" vertical></View>
              </View>
            </View>
          </View>
          <div className={styles.rightContainer}></div>
        </View>
        <View
          gap="large"
          className={`${styles.wrapper} ${styles.borderedWrapper}`}
        >
          <View className={styles.logoContainer}>
            <Paragraph className={styles.bottomText} size="small">
              © 2023 devpage. All rights reserved.
            </Paragraph>
          </View>
          <div className={styles.rightContainer}>
            <Button
              linkClassName={styles.socialButton}
              className={styles.socialButton}
              size="small"
            >
              <Twitter
                fill="var(--neutral-400)"
                color="var(--neutral-400)"
                size="16"
              />
            </Button>
            <Button
              linkClassName={styles.socialButton}
              className={styles.socialButton}
              size="small"
            >
              <Linkedin
                fill="var(--neutral-400)"
                color="var(--neutral-400)"
                size="16"
              />
            </Button>
          </div>
        </View>
      </footer>
    );
  }
}
