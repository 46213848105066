import { useApolloClient } from "@apollo/client";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { RENAME_SECTION } from "../../../graphql/mutations/Sections";
import { GET_SECTION_BY_SECTIONID } from "../../../graphql/queries/Sections";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/CookiePreferencesModal.module.css";
import Spacer from "../../view/impl/Spacer";
import { CheckCircle, StopCircle, XCircle } from "react-feather";

export default function CookiePreferencesModal(props) {
  const { acceptedCookies, setAcceptedCookies, setHideCookieBanner } = useContext(StorageContext);

  const { darkMode } = useContext(ConfigContext);

  const [trackingState, setTrackingState] = useState(
    acceptedCookies && acceptedCookies.trackingCookies
      ? acceptedCookies.trackingCookies
      : false
  );

  const { closeOverlay } = useContext(OverlayContext);

  function updateCookieSettings(state) {
    setTrackingState(state);
    if (acceptedCookies) {
      let previousSettings = acceptedCookies;
      previousSettings.trackingCookies = state;
      setAcceptedCookies(previousSettings);
      localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookies));
    } else {
      setAcceptedCookies({
        trackingCookies: state,
      });
      localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookies));
    }
  }

  function savePreferences() {
    if (acceptedCookies) {
      let previousSettings = acceptedCookies;
      previousSettings.closed = true;
      setAcceptedCookies(previousSettings);
      localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookies));
    } else {
      setAcceptedCookies({
        closed: true,
      });
      localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookies));
    }
    setHideCookieBanner(true);
    closeOverlay();
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <Paragraph size="small" className={styles.messageText}>
          We use cookies and/or similar technologies to measure and improve your
          experience with devpage. Certain cookies (necessary cookies) are
          required for devpage to function.
        </Paragraph>
        <Spacer size="medium" />
        <Accordion allowMultiple className={styles.accordion}>
          <AccordionItem className={`${styles.accordionItem}`}>
            <AccordionButton className={`${styles.accordionButton}`}>
              <View gap="small" centerVertically>
                <CheckCircle size="16" color="var(--green)" />
                <H6 className={styles.smallTitle}>Necessary Cookies</H6>
              </View>
              <View className={styles.rightContainer}>
                <AccordionIcon
                  className={`${styles.accordionIcon} ${
                    darkMode && styles.darkModeAccordionIcon
                  }`}
                />
              </View>
            </AccordionButton>
            <AccordionPanel className={`${styles.accordionPanel}`}>
              <Paragraph className={styles.capWidth} size="small">
                These are the cookies required for devpage to function.
              </Paragraph>
            </AccordionPanel>
          </AccordionItem>
          <Separator className={styles.separator} />
          <AccordionItem className={`${styles.accordionItem}`}>
            <AccordionButton className={`${styles.accordionButton}`}>
              <View gap="small" centerVertically>
                {trackingState === true ? (
                  <CheckCircle size="16" color="var(--green)" />
                ) : (
                  <XCircle size="16" color="var(--red)" />
                )}
                <H6 className={styles.smallTitle}>
                  Product Analytics / Tracking Cookies
                </H6>
              </View>
              <View className={styles.rightContainer}>
                <AccordionIcon
                  className={`${styles.accordionIcon} ${
                    darkMode && styles.darkModeAccordionIcon
                  }`}
                />
              </View>
            </AccordionButton>
            <AccordionPanel className={`${styles.accordionPanel}`}>
              <View gap="medium" centerVertically>
                {acceptedCookies === null ||
                acceptedCookies.trackingCookies === undefined ? (
                  <Switch
                    onChange={() => updateCookieSettings(true)}
                    defaultChecked={false}
                  />
                ) : (
                  <Switch
                    onChange={() =>
                      updateCookieSettings(!acceptedCookies.trackingCookies)
                    }
                    defaultChecked={acceptedCookies.trackingCookies}
                  />
                )}
                <Paragraph className={styles.capWidth} size="small">
                  Allow analytical cookies that helps us understand how you use
                  our product to help us design better experiences.
                </Paragraph>
              </View>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={savePreferences}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            Save Preferences
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
