import { Tooltip } from "evergreen-ui";
import React, { useContext, useEffect, useState } from "react";
import { AlertCircle, AlertTriangle, Bookmark, Check, Trash2, Triangle } from "react-feather";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import Alert from "../../text/alert/Alert";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/ProfileSettingsModal.module.css";
import TextInput from "../../input/TextInput";
import { useApolloClient } from "@apollo/client";
import { GET_USER_BY_EMAIL, LINK_EXISTS } from "../../../graphql/queries/Users";
import { ConfigContext } from "../../../context/ConfigProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { SectionContext } from "../../../context/SectionProvider";
import Spacer from "../../view/impl/Spacer";
import ProfilePicture from "../../view/impl/ProfilePicture";
import TextArea from "../../input/TextArea";
import PrefixTextInput from "../../input/PrefixTextInput";
import Link from "../../links/Link";
import {
  SET_USER_PRIVACY,
  SOFT_DELETE,
  UPDATE_PROFILE,
  UPDATE_USER,
} from "../../../graphql/mutations/Users";
import { Switch } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function ProfileSettingsModal(props) {
  const { userDetails, setUserDetails } = useContext(StorageContext);

  const [loading, setLoading] = useState(true);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  const [name, setName] = useState("");

  const [link, setLink] = useState("");

  const [bio, setBio] = useState("");

  const [location, setLocation] = useState("");

  const [picture, setPicture] = useState("");

  const [confirmDelete, setConfirmDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getUser() {
      const response = await client
        .query({
          query: GET_USER_BY_EMAIL,
          variables: {
            email: userDetails.email,
          },
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          setError({
            type: 1,
            message: error.message,
          });
        });
      if (response && response.data && response.data.getUserByEmail) {
        setName(response.data.getUserByEmail.name);
        setLink(response.data.getUserByEmail.link);
        setBio(response.data.getUserByEmail.bio);
        setLocation(response.data.getUserByEmail.location);
        setPicture(response.data.getUserByEmail.picture);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
    setLoading(true);
    if (!isOverlayVisible) {
      setName("");
      setLink("");
      setBio("");
      setLocation("");
      setPicture("");
      setConfirmDelete(false);
    } else {
      getUser();
    }
  }, [isOverlayVisible]);

  async function updateProfilePrivacy(privacy) {
    const response = await client
      .mutate({
        mutation: SET_USER_PRIVACY,
        variables: {
          email: userDetails.email,
          private: privacy,
        },
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
    if (response && response.data && response.data.updateUser) {
      setUserDetails(response.data.updateUser);
    }
  }

  async function deleteUser() {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      let response = await client
      .mutate({
        mutation: SOFT_DELETE,
        variables: {
          email: userDetails.email,
          archived: true
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
      if (response && response.data && response.data.updateUser) {
        closeOverlay();
        setUserDetails(null);
        navigate("/");
      }
    }
  }

  async function updateUser() {
    if (!link || !name) {
      setError({
        type: 1,
        message: "Please fill in all required fields.",
      });
      return;
    }
    setError(null);
    let response = await client
      .query({
        query: LINK_EXISTS,
        variables: {
          link: link,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response && response.data) {
      const linkExists = response.data.linkExists;
      if (!linkExists || userDetails.link === link) {
        response = await client
          .mutate({
            mutation: UPDATE_PROFILE,
            variables: {
              email: userDetails.email,
              name: name || "",
              link: link || "",
              bio: bio || "",
              location: location || "",
              picture: picture || "",
            },
          })
          .catch((error) => {
            console.error("Error updating user:", error);
          });
        if (response.data.updateUser) {
          const updatedUserDetails = response.data.updateUser; // Assuming the response contains the updated user details
          setUserDetails(updatedUserDetails);
        } else {
          console.error("Error updating profile:", response);
        }
        setTimeout(() => {
          closeOverlay();
          setName("");
          setLink("");
          setBio("");
          setLocation("");
          setPicture("");
          setUpdate(true);
        }, 500);
      } else {
        setError({
          type: 1,
          message: "This link is already in use. Please try another.",
        });
        setUpdate(true);
      }
    }
  }

  if (!userDetails) {
    return undefined;
  }

  function setLinkInput(link) {
    setError(null);
    setLink(link);
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <View centerHorizontally centerVertically vertical>
          <Spacer size="small" />
          <ProfilePicture name={userDetails.name} url={userDetails.picture} />
          <Spacer size="large" />
          <Paragraph
            size="small"
            className={`${styles.messageText} ${styles.inputTitle}`}
          >
            Link*
          </Paragraph>
          <Spacer size="small" />
          <PrefixTextInput
            value={link}
            onChange={(e) => setLinkInput(e.target.value)}
            prefixText="devpa.ge/"
            className={`${styles.nameInput} ${error !== null && error.type === 1 && styles.ctaInputError}`}

            placeholder="devpage"
          />
          {
                  error !== null && error.type === 1 && (
                    <View className={styles.errorContainer} centerVertically centerHorizontally>
                      <AlertCircle className={styles.errorIcon} size="14" color="var(--red)" />
                      <Paragraph size="small" className={styles.errorMessage}>{error.message}</Paragraph>
                      </View>
                  )
                }
          <Spacer size="medium" />
          <Paragraph
            size="small"
            className={`${styles.noteText} ${styles.inputTitle}`}
          >
            <b>
              <span style={{ color: "var(--neutral-100)" }}>Note:</span>
            </b>{" "}
            Your link is public unless you explicitly set your profile to
            private.
          </Paragraph>
          <Spacer size="large" />
          <Paragraph
            size="small"
            className={`${styles.messageText} ${styles.inputTitle}`}
          >
            Name*
          </Paragraph>
          <Spacer size="small" />
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.nameInput}
            placeholder="E.g. David"
          />
          <Spacer size="medium" />
          <Paragraph
            size="small"
            className={`${styles.messageText} ${styles.inputTitle}`}
          >
            Biography (optional)
          </Paragraph>
          <Spacer size="small" />
          <TextArea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className={styles.nameInput}
            placeholder="E.g. Software developer from Sweden"
          />
          <Spacer size="medium" />
          <Paragraph
            size="small"
            className={`${styles.messageText} ${styles.inputTitle}`}
          >
            Avatar (optional)
          </Paragraph>
          <Spacer size="small" />
          <TextInput
            value={picture}
            onChange={(e) => setPicture(e.target.value)}
            className={styles.nameInput}
            placeholder="E.g. https://example.com/avatar.png"
          />
          <Spacer size="medium" />
          <Paragraph
            size="small"
            className={`${styles.messageText} ${styles.inputTitle}`}
          >
            Location (optional)
          </Paragraph>
          <Spacer size="small" />
          <TextInput
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className={styles.nameInput}
            placeholder="E.g. Gothenburg, Sweden"
          />
          <Spacer size="medium" />
          <View vertical>
            <Paragraph
              size="small"
              className={`${styles.messageText} ${styles.inputTitle}`}
            >
              Private profile
            </Paragraph>
            <Spacer size="small" />
            <Switch
              size="lg"
              onChange={() => updateProfilePrivacy(!userDetails.private)}
              defaultChecked={userDetails.private}
              id="privateProfile"
            />
          </View>
        </View>
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View vertical>
        <SecondaryButton
          onClick={deleteUser}
          linkClassName={`${styles.fullWidthButton} ${styles.iconButton}`}
          className={`${styles.fullWidthButton} ${styles.iconButton}`}
          size="small"
        >
          <Trash2 size="16" />
          Delete account
        </SecondaryButton>
        {
                  confirmDelete && (
                    <View className={styles.errorContainer} centerVertically centerHorizontally>
                      <AlertTriangle className={styles.errorIcon} size="14" color="var(--red)" />
                      <Paragraph size="small" className={styles.errorMessage}>Press the button again to delete your account.</Paragraph>
                      </View>
                  )
                }
        </View>
        <View className={styles.buttonWrapper} gap="medium">
          <PrimaryButton
            spinner
            onClick={updateUser}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            Save changes
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
