import { useApolloClient } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { AlertContext } from "../../../context/AlertProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { RENAME_SECTION } from "../../../graphql/mutations/Sections";
import { GET_SECTION_BY_SECTIONID } from "../../../graphql/queries/Sections";
import PrimaryButton from "../../buttons/PrimaryButton";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/ShareLinkModal.module.css";
import Spacer from "../../view/impl/Spacer";
import Button from "../../buttons/Button";
import { ChevronRight, Copy, Plus, Share2 } from "react-feather";
import SecondaryButton from "../../buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, HatenaIcon, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

export default function ShareLinkModal(props) {
  const [loading, setLoading] = useState(true);

  const { userDetails } = useContext(StorageContext);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  const [name, setName] = useState("");

  const [copied, setCopied] = useState(false);

  const { showAlert } = useContext(AlertContext);

  const navigate = useNavigate();

  async function updateSection() {
    await client
      .mutate({
        mutation: RENAME_SECTION,
        variables: {
          sectionId: props.sectionId,
          name,
        },
      })
      .catch((error) => {
        console.error("Error editing link:", error);
      });
    setTimeout(() => {
      closeOverlay();
      setName("");
      setUpdate(true);
      props.onUpdate();
    }, 500);
  }

  function copyLink() {
    if (copied) {
      return;
    }
    navigator.clipboard.writeText("https://devpa.ge/" + props.user.link);
    setCopied(true);
    showAlert("Link copied to clipboard!", "success");
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  function navigateToLink(link) {
    closeOverlay();
    navigate(link);
  }

  function getShareText() {
    var text = "Hey everyone, check out " + (userDetails && userDetails.email === props.user.email ? "my" : "this") + " devpa.ge!\n\nhttps://devpa.ge/" + props.user.link + " #devpage @getdevpage";
    return encodeURIComponent(text);
  }

  function share(url) {
    window.open(
      url,
      "Share",
      "width=600,height=400,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1"
    );
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <View gap="small">
          <TwitterShareButton url={"https://devpa.ge/" + props.user.link}>
            <TwitterIcon size={35} round={true} />
          </TwitterShareButton>
          <FacebookShareButton url={"https://devpa.ge/" + props.user.link}>
            <FacebookIcon size={35} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={"https://devpa.ge/" + props.user.link}>
            <WhatsappIcon size={35} round={true} />
          </WhatsappShareButton>
          <RedditShareButton url={"https://devpa.ge/" + props.user.link}>
            <RedditIcon size={35} round={true} />
          </RedditShareButton>
          <TelegramShareButton url={"https://devpa.ge/" + props.user.link}>
            <TelegramIcon size={35} round={true} />
          </TelegramShareButton>
          <EmailShareButton>
            <EmailIcon size={35} round={true} />
          </EmailShareButton>
        </View>
        <Spacer size="medium" />
        <Spacer size="small" />
        <Button
          tooltip="Press to copy this link"
          onClick={() => copyLink()}
          className={`${styles.shareButton} ${styles.linkShareButton}`}
        >
          <Copy size="26" color="var(--neutral-100)"/>
          devpa.ge/{props.user.link}
          <View className={styles.rightContainer}>
            <Paragraph size="small">{copied ? "Copied!" : "Copy"}</Paragraph>
          </View>
        </Button>
        {!userDetails && (
          <View vertical>
            <Spacer size="medium" />
            <Spacer size="small" />
            <H6 className={styles.smallTitle}>Create your own devpa.ge</H6>
            <Spacer size="small" />
            <Paragraph size="small" className={styles.smallParagraph}>
              No credit card required.
            </Paragraph>
            <Spacer size="small" />
            <Spacer size="small" />
            <View vertical gap="medium">
              <PrimaryButton
                onClick={() => navigateToLink("/join")}
                size="medium"
                className={styles.ctaButton}
              >
                Sign up for free
              </PrimaryButton>
              <SecondaryButton
                onClick={() => navigateToLink("/")}
                size="medium"
                className={styles.ctaButton}
              >
                Find out more
              </SecondaryButton>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
