import { useApolloClient } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../context/ConfigProvider";
import { OverlayContext } from "../../../context/OverlayProvider";
import { StorageContext } from "../../../context/StorageProvider";
import { UPDATE_LINK_URI_AND_SLUG } from "../../../graphql/mutations/Links";
import { GET_LINK_BY_LINKID } from "../../../graphql/queries/Links";
import PrimaryButton from "../../buttons/PrimaryButton";
import PrefixTextInput from "../../input/PrefixTextInput";
import TextInput from "../../input/TextInput";
import H6 from "../../text/heading/H6";
import Paragraph from "../../text/paragraph/Paragraph";
import View from "../../view/View";
import Separator from "../../view/impl/Separator";
import styles from "./styles/EditLinkModal.module.css";
import Spacer from "../../view/impl/Spacer";

export default function EditLinkModal(props) {
    
  const [loading, setLoading] = useState(true);

  const { userDetails } = useContext(StorageContext);

  const { isOverlayVisible, closeOverlay } = useContext(OverlayContext);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  const { setUpdate } = useContext(ConfigContext);

  const [linkUri, setLinkUri] = useState("");

  const [slug, setSlug] = useState("");

  const [desc, setDesc] = useState("");

  const [thumbnail, setThumbnail] = useState("");

  useEffect(() => {
    async function getLink() {
      const response = await client
        .query({
          query: GET_LINK_BY_LINKID,
          variables: {
            linkId: props.linkId,
          },
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          setError({
            type: 1,
            message: error.message,
          });
        });
      if (response && response.data && response.data.getLinkByLinkId) {
        setLinkUri(response.data.getLinkByLinkId.uri);
        setSlug(response.data.getLinkByLinkId.slug);
        setDesc(response.data.getLinkByLinkId.description);
        setThumbnail(response.data.getLinkByLinkId.thumbnail);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
    setLoading(true);
    if (!isOverlayVisible) {
      setLinkUri("");
      setSlug("");
      setDesc("");
      setThumbnail("");
    } else {
      getLink();
    }
  }, [isOverlayVisible]);

  async function updateLink() {
    await client
      .mutate({
        mutation: UPDATE_LINK_URI_AND_SLUG,
        variables: {
          linkId: props.linkId,
          uri: linkUri,
          slug: slug,
          description: desc,
          thumbnail: thumbnail,
        },
      })
      .catch((error) => {
        console.error("Error editing link:", error);
      });
    setTimeout(() => {
      closeOverlay();
      setLinkUri("");
      setSlug("");
      setDesc("");
      setThumbnail("");
      setUpdate(true);
      props.onUpdate();
    }, 500);
  }

  if (loading) {
    return (
      <View pad="large" centerVertically centerHorizontally>
        <View pad="large" centerVertically centerHorizontally>
          <Spinner color="var(--neutral-800)" />
        </View>
      </View>
    );
  }

  return (
    <View vertical>
      <View className={styles.paddedContainer} vertical>
        <H6>Edit '{slug}'</H6>
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          Please enter the URL of your link.
        </Paragraph>
        <Spacer size="small" />
        <PrefixTextInput
          size="small"
          value={linkUri.toString().replace("https://", "")}
          onChange={(e) => setLinkUri("https://" + e.target.value)}
          className={styles.fullWidth}
          prefixText="https://"
          placeholder="example.com"
        />
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          Please enter the slug of your link.
        </Paragraph>
        <Spacer size="small" />
        <TextInput
          size="small"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
          className={styles.fullWidth}
          placeholder="E.g. Google"
        />
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          Please enter the description of your link.
        </Paragraph>
        <Spacer size="small" />
        <TextInput
          size="small"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          className={styles.fullWidth}
          placeholder="E.g. the link to my Google profile"
        />
        <Spacer size="medium" />
        <Paragraph size="small" className={styles.messageText}>
          Please enter the thumbnail of your link.
        </Paragraph>
        <Spacer size="small" />
        <TextInput
          size="small"
          value={thumbnail}
          onChange={(e) => setThumbnail(e.target.value)}
          className={styles.fullWidth}
          placeholder="E.g. https://example.com/image.png"
        />
      </View>
      <Separator className={styles.separator} />
      <View className={styles.buttonContainer}>
        <View className={styles.buttonWrapper}>
          <PrimaryButton
            spinner
            onClick={updateLink}
            linkClassName={styles.fullWidthButton}
            className={styles.fullWidthButton}
            size="small"
          >
            Confirm
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
}
