import "./App.css";
import Main from "./Main";
import { ConfigProvider } from "./context/ConfigProvider";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { StorageProvider } from "./context/StorageProvider";
import { useEffect } from "react";

export default function App() {

  const storeToken = (token) => {
    localStorage.setItem("token", token);
  };

  const getToken = () => {
    const token = /*localStorage.getItem("token")*/"client";
    return token;
  };

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = getToken();
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      authMiddleware,
      new HttpLink({ uri: "http://localhost:3112/graphql" }),
    ]),
  });

  return (
    <ApolloProvider client={client}>
      <StorageProvider>
        <ConfigProvider>
          <Main />
        </ConfigProvider>
      </StorageProvider>
    </ApolloProvider>
  );
}
