import { useApolloClient } from "@apollo/client";
import {
  Menu,
  MenuButton,
  MenuList, Spinner, Tooltip
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import {
  Archive,
  Check,
  ChevronDown,
  Edit2,
  ExternalLink,
  Image,
  MapPin,
  Plus,
  RotateCcw,
  Trash2,
  Upload
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../context/AlertProvider";
import { ConfigContext } from "../../context/ConfigProvider";
import { OverlayContext } from "../../context/OverlayProvider";
import { SectionContext } from "../../context/SectionProvider";
import { StorageContext } from "../../context/StorageProvider";
import {
  UPDATE_LINKS,
  UPDATE_LINK_VISIBILITY,
} from "../../graphql/mutations/Links";
import {
  UPDATE_SECTIONS,
  UPDATE_SECTIONS_VISIBILITY,
} from "../../graphql/mutations/Sections";
import {
  PUBLISH_APPEARANCE,
  PUBLISH_SECTIONS,
  UPDATE_APPEARANCE_SETTINGS,
  VERIFY,
} from "../../graphql/mutations/Users";
import { GET_SECTIONS_BY_OWNER } from "../../graphql/queries/Sections";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import ComponentTextInput from "../input/ComponentTextInput";
import TextInput from "../input/TextInput";
import Link from "../links/Link";
import Modal from "../modals/Modal";
import ConfirmDeletion from "../modals/impl/ConfirmDeletion";
import CreateLinkModal from "../modals/impl/CreateLinkModal";
import CreateSectionModal from "../modals/impl/CreateSectionModal";
import EditLinkModal from "../modals/impl/EditLinkModal";
import EditSectionModal from "../modals/impl/EditSectionModal";
import ProfileSettingsModal from "../modals/impl/ProfileSettingsModal";
import RadioCellContainer from "../radiocell/RadioCellContainer";
import H5 from "../text/heading/H5";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import ProfilePicture from "../view/impl/ProfilePicture";
import Spacer from "../view/impl/Spacer";
import User from "./User";
import SectionList from "./devpage/SectionList";
import styles from "./styles/Dashboard.module.css";

export default function Dashboard() {
  const { showAlert } = useContext(AlertContext);

  const { userDetails, setUserDetails } = useContext(StorageContext);

  const { sectionData, setSectionData } = useContext(SectionContext);

  const {
    setUpdate,
    selectedIds,
    setSelectedIds,
    appearanceSettings,
    setAppearanceSettings,
  } = useContext(ConfigContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const client = useApolloClient();

  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'Dashboard – devpa.ge';
  }, []);

  useEffect(() => {
    if (userDetails === null) {
      navigate("/");
      return;
    }
    setAppearanceSettings(JSON.parse(userDetails.appearanceSettings));
  }, [userDetails]);

  const { isOverlayVisible, openOverlay, closeOverlay } =
    useContext(OverlayContext);

  // fake data generator
  const getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`,
      content: `item ${k}`,
    }));

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });

  const [items, setItems] = useState(getItems(10));

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const handleToggle = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    localStorage.setItem("selectedAccordionIds", JSON.stringify(selectedIds));
  }, [selectedIds]);

  const openProfileSettingsModal = () => {
    openOverlay(
      <Modal title="Edit your profile">
        <ProfileSettingsModal onUpdate={() => onUpdate(6)} />
      </Modal>
    );
  };

  const openSectionModal = () => {
    openOverlay(
      <Modal title="New Section">
        <CreateSectionModal onUpdate={() => onUpdate(1)} />
      </Modal>
    );
  };

  const openLinkModal = (sectionId) => {
    openOverlay(
      <Modal title="New Link">
        <CreateLinkModal sectionId={sectionId} onUpdate={() => onUpdate(2)} />
      </Modal>
    );
  };

  const openEditLinkModal = (linkId) => {
    openOverlay(
      <Modal title="Edit Link">
        <EditLinkModal linkId={linkId} onUpdate={() => onUpdate(4)} />
      </Modal>
    );
  };

  const openEditSectionModal = (sectionId) => {
    openOverlay(
      <Modal title="Edit Section">
        <EditSectionModal sectionId={sectionId} onUpdate={() => onUpdate(5)} />
      </Modal>
    );
  };

  const openDeletionModal = () => {
    openOverlay(
      <Modal title="Confirm Deletion">
        <ConfirmDeletion onUpdate={() => onUpdate(3)} />
      </Modal>
    );
  };

  async function verify(email, verified) {
    await client
      .mutate({
        mutation: VERIFY,
        variables: {
          email: email,
          verification: verified,
        },
      })
      .catch((error) => {
        console.error("Error verifying user:", error);
      });
    showAlert("Verification status updated.", "success");
  }

  async function setLinkVisibility(linkId, visibility) {
    await client
      .mutate({
        mutation: UPDATE_LINK_VISIBILITY,
        variables: {
          linkId: linkId,
          owner: userDetails.email,
          visible: !visibility,
        },
      })
      .catch((error) => {
        console.error("Error updating link:", error);
      });
    getSections();
  }

  async function setSectionVisibility(sectionIds, visibility) {
    await client
      .mutate({
        mutation: UPDATE_SECTIONS_VISIBILITY,
        variables: {
          sectionIds: sectionIds.toString(),
          owner: userDetails.email,
          visible: visibility !== false ? false : true,
        },
      })
      .catch((error) => {
        console.error("Error updating section:", error);
      });
    getSections();
  }

  const handleDrop = (droppedItem) => {
    console.log("handleDrop");
  };

  const [filledButtonData, setFilledButtonData] = useState({
    questions: [
      {
        id: 1,
        questionType: 1,
        questionTitle: null,
        questionSubtitle: null,
        question: null,
        unique: true,
        hideButton: true,
        transparentBackground: true,
        options: [
          {
            id: 1,
            component: (
              <button
                className={`${styles.fullWidthButton} ${styles.solidButton}`}
              >
                Button
              </button>
            ),
          },
          {
            id: 2,
            component: (
              <button
                className={`${styles.fullWidthButton} ${styles.roundedButton}`}
              >
                Button
              </button>
            ),
          },
          {
            id: 3,
            component: (
              <button
                className={`${styles.fullWidthButton} ${styles.roundButton}`}
              >
                Button
              </button>
            ),
          },
        ],
      },
    ],
  });

  const [backgroundData, setBackgroundData] = useState({
    questions: [
      {
        id: 1,
        questionType: 1,
        questionTitle: null,
        questionSubtitle: null,
        question: null,
        unique: true,
        preventUnselect: true,
        hideButton: true,
        transparentBackground: true,
        options: [
          {
            id: 1,
            title: "Fill",
            titleSize: 14,
            selected: true,
            component: <View className={`${styles.background}`}></View>,
          },
          {
            id: 2,
            title: "Gradient",
            titleSize: 14,
            component: (
              <View
                className={`${styles.background} ${styles.gradientBackground}`}
              ></View>
            ),
          },
          {
            id: 3,
            title: "Image",
            titleSize: 14,
            component: (
              <View className={`${styles.background}`}>
                <Image color="var(--neutral-700)" size="30px" />
              </View>
            ),
          },
        ],
      },
    ],
  });

  const { darkMode, dashboardTab } = useContext(ConfigContext);

  async function getSections() {
    if (!userDetails) {
      return;
    }
    const response = await client
      .query({
        query: GET_SECTIONS_BY_OWNER,
        variables: {
          owner: userDetails.email,
        },
        fetchPolicy: "no-cache",
      })
      .catch((error) => {
        setError({
          type: 1,
          message: error.message,
        });
      });
    if (response) {
      if (response.data && response.data.getSectionsByOwner) {
        if (response.data.getSectionsByOwner.length === 0) {
          setSectionData(null);
        } else {
          console.log(
            "response.data.getSectionsByOwner:",
            response.data.getSectionsByOwner
          );
          setSectionData(response.data.getSectionsByOwner);
        }
      }
    }
    const storedSelectedIds = localStorage.getItem("selectedAccordionIds");
    setSelectedIds(storedSelectedIds ? JSON.parse(storedSelectedIds) : []);
    setLoading(false);
  }

  async function onUpdate(type) {
    switch (type) {
      case 1:
        showAlert("Section created successfully.", "success");
        break;

      case 2:
        showAlert("Link added successfully.", "success");
        break;

      case 3:
        const sections = selectedIds
          .filter((item) => item.toString().includes("section: "))
          .map((item) => item.toString().replace("section: ", ""));
        const links = selectedIds
          .filter((item) => item.toString().includes("link: "))
          .map((item) => item.toString().replace("link: ", ""));
        const remainingIds = selectedIds.filter(
          (item) =>
            !item.toString().includes("section: ") &&
            !item.toString().includes("link: ")
        );
        if (sections.length > 0) {
          await client
            .mutate({
              mutation: UPDATE_SECTIONS,
              variables: {
                sectionIds: sections.toString(),
                owner: userDetails.email,
                archived: true,
              },
            })
            .catch((error) => {
              console.error("Error deleting section:", error);
            });
        }
        if (links.length > 0) {
          await client
            .mutate({
              mutation: UPDATE_LINKS,
              variables: {
                linkIds: links.toString(),
                owner: userDetails.email,
                archived: true,
              },
            })
            .catch((error) => {
              console.error("Error deleting link:", error);
            });
        }
        setSelectedIds(remainingIds);
        showAlert("Items deleted successfully.", "success");
        break;

      case 4:
        showAlert("Link updated successfully.", "success");
        break;

      case 5:
        showAlert("Section updated successfully.", "success");
        break;
    }
    getSections();
  }

  useEffect(() => {
    if (userDetails) {
      getSections();
    }
  }, [userDetails]);

  const getChangedPos = (currentPos, newPos) => {
    console.log(currentPos, newPos);
  };

  const getChangedPos2 = (currentPos, newPos) => {
    console.log(currentPos, newPos);
  };

  async function publish() {
    let response = await client
      .mutate({
        mutation: UPDATE_APPEARANCE_SETTINGS,
        variables: {
          email: userDetails.email,
          appearanceSettings: JSON.stringify(appearanceSettings),
        },
      })
      .catch((error) => {
        console.error("Error verifying user:", error);
      });
    if (response && response.data && response.data.updateUser) {
      console.log(
        "9 setting appearance settings to:",
        JSON.parse(response.data.updateUser.appearanceSettings)
      );
      setUserDetails(response.data.updateUser);
      setAppearanceSettings(
        JSON.parse(response.data.updateUser.appearanceSettings)
      );
    }
    response = await client
      .mutate({
        mutation: PUBLISH_SECTIONS,
        variables: {
          email: userDetails.email,
          published: JSON.stringify(sectionData),
        },
      })
      .catch((error) => {
        console.error("Error publishing sectionData:", error);
      });
    if (response && response.data && response.data.updateUser) {
      userDetails.published = response.data.updateUser.published;
    } else {
      showAlert(
        "Something went wrong when attempting to publish your profile.",
        "error"
      );
      return;
    }
    response = await client
      .mutate({
        mutation: PUBLISH_APPEARANCE,
        variables: {
          email: userDetails.email,
          publishedAppearance: JSON.stringify(appearanceSettings),
        },
      })
      .catch((error) => {
        console.error("Error publishing appearanceSettings:", error);
      });
    if (response && response.data && response.data.updateUser) {
      setUserDetails(response.data.updateUser);
      setAppearanceSettings(
        JSON.parse(response.data.updateUser.appearanceSettings)
      );
    } else {
      showAlert(
        "Something went wrong when attempting to publish your profile.",
        "error"
      );
      return;
    }
    showAlert("Your profile has been saved.", "success");
  }

  if (!userDetails || !appearanceSettings) {
    return undefined;
  }

  function CurrentTab() {
    switch (dashboardTab) {
      case "Profile":
        return (
          <View vertical className={styles.contentContainer}>
            <View
              vertical
              className={`${styles.content} ${styles.profileContent}`}
            >
              <View pad="large">
                <View gap="large" vertical>
                  {userDetails && (
                    <ProfilePicture
                      name={userDetails.name}
                      url={userDetails.picture}
                    />
                  )}
                  <View vertical>
                    <View centerVertically>
                      <H5>{userDetails.name}</H5>
                      {userDetails.verification > 0 && (
                        <Tooltip
                          className={styles.tooltip}
                          label="You are verified."
                        >
                          <img
                            className={styles.verified}
                            src={
                              userDetails.verification === 2
                                ? "./assets/svgs/verified.svg"
                                : "./assets/svgs/verified.svg"
                            }
                          />
                        </Tooltip>
                      )}

                      <View
                        className={`${styles.rightContainer} ${styles.hide670}`}
                        centerVertically
                      >
                        <SecondaryButton
                          onClick={openProfileSettingsModal}
                          className={styles.iconButton}
                          size="small"
                        >
                          <Edit2 size="14" />
                          Edit Profile
                        </SecondaryButton>
                      </View>
                    </View>
                    <View centerVertically>
                      <Paragraph className={styles.handle}>
                        @{userDetails.link}
                      </Paragraph>
                      <Link
                        target="_blank"
                        to={"https://devpa.ge/" + userDetails.link}
                      >
                        <Tooltip
                          className={styles.tooltip}
                          label="Open link in a new tab"
                        >
                          <ExternalLink color="var(--neutral-100)" size="14" />
                        </Tooltip>
                      </Link>
                    </View>
                    <Spacer size="medium" />
                    <Paragraph>
                      {userDetails.bio || "No bio available."}
                    </Paragraph>
                  </View>
                </View>
              </View>
              {userDetails.location && (
                <View
                  className={styles.locationContainer}
                  gap="small"
                  centerVertically
                >
                  <MapPin color="var(--neutral-500)" size={14} />
                  <Paragraph size="small">{userDetails.location}</Paragraph>
                </View>
              )}
              <View
                className={`${styles.mobileEditProfileButtonContainer} ${styles.show670}`}
              >
                <SecondaryButton
                  size="medium"
                  onClick={openProfileSettingsModal}
                  linkClassName={styles.fullWidthButton}
                  className={`${styles.fullWidthButton} ${styles.iconButton}`}
                >
                  <Edit2 size="16" />
                  Edit Profile
                </SecondaryButton>
              </View>
            </View>
          </View>
        );

      case "Links":
        function getSelectables() {
          return selectedIds.filter(
            (item) =>
              item.toString().includes("link: ") ||
              item.toString().includes("section: ")
          );
        }

        return (
          <View vertical className={styles.contentContainer}>
            <View centerVertically className={styles.titleContainer}>
              <H6>
                <span className={styles.darkerText}>devpa.ge/</span>
                {userDetails.link}
              </H6>
              <Spacer size="small" />
              <Link
                target="_blank"
                to={"https://devpa.ge/" + userDetails.link}
              >
                <Tooltip
                  className={styles.tooltip}
                  label="Open link in a new tab"
                >
                  <ExternalLink size="16" />
                </Tooltip>
              </Link>
              <View className={styles.rightContainer}>
                {(sectionData === null || sectionData.length === 0) &&
                (appearanceSettings === null ||
                  appearanceSettings.length === 0) ? (
                  <PrimaryButton
                    disabled
                    className={styles.iconButton}
                    size="small"
                  >
                    <Check size="16" />
                    Nothing to publish
                  </PrimaryButton>
                ) : userDetails.published === null ||
                  userDetails.publishedAppearance === null ||
                  userDetails.published !== JSON.stringify(sectionData) ||
                  userDetails.publishedAppearance !==
                    JSON.stringify(appearanceSettings) ? (
                  <PrimaryButton
                  spinner
                    className={styles.iconButton}
                    size="small"
                    onClick={() => publish()}
                  >
                    <Upload size="16" />
                    Publish
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    disabled
                    className={styles.iconButton}
                    size="small"
                  >
                    <Check size="16" />
                    Up to date
                  </PrimaryButton>
                )}
              </View>
            </View>
            <View vertical className={styles.content}>
              {loading && (
                <View
                  pad="large"
                  centerVertically
                  centerHorizontally
                  className={styles.fullHeight}
                >
                  <Spinner color="var(--neutral-800)" />
                </View>
              )}
              {!loading && sectionData === null && (
                <View
                  gap="medium"
                  centerVertically
                  centerHorizontally
                  vertical
                  className={styles.emptyDataContainer}
                >
                  <Archive color="var(--neutral-100)" size="20" />
                  <Paragraph size="small">
                    You haven't added any links yet.
                  </Paragraph>
                </View>
              )}
              {!loading && (
                <SectionList
                  getSections={getSections}
                  setSelectedIds={setSelectedIds}
                  openEditLinkModal={openEditLinkModal}
                  openLinkModal={openLinkModal}
                  setLinkVisibility={setLinkVisibility}
                  setSectionVisibility={setSectionVisibility}
                  openEditSectionModal={openEditSectionModal}
                  handleToggle={handleToggle}
                  sectionData={sectionData}
                  selectedIds={selectedIds}
                />
              )}
            </View>
            <View
              gap="medium"
              centerVertically
              className={styles.bottomButtonContainer}
            >
              <PrimaryButton
                onClick={openSectionModal}
                className={styles.iconButton}
                size="small"
              >
                <Plus size="16" />
                New section
              </PrimaryButton>
              <SecondaryButton
                onClick={openDeletionModal}
                disabled={getSelectables().length === 0}
                className={styles.iconButton}
                size="small"
              >
                <Trash2 size="16" />
                {getSelectables().length === 0
                  ? "Delete selected"
                  : "Delete selected (" + getSelectables().length + ")"}
              </SecondaryButton>
              <Spacer size="medium" />
            </View>
          </View>
        );

      case "Appearance":
        
        const [backgroundColor, setBackgroundColor] = useState(
          appearanceSettings.backgroundColor
        );

        const [gradientColor1, setGradientColor1] = useState(
          appearanceSettings.gradientColor1
        );

        const [gradientColor2, setGradientColor2] = useState(
          appearanceSettings.gradientColor2
        );

        const [sectionColor, setSectionColor] = useState(
          appearanceSettings.sectionColor
        );

        const [nameColor, setNameColor] = useState(
          appearanceSettings.nameColor
        );

        const [bioColor, setBioColor] = useState(
          appearanceSettings.bioColor
        );

        const [locationColor, setLocationColor] = useState(
          appearanceSettings.locationColor
        );

        const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
          appearanceSettings.buttonBackgroundColor
        );

        const [buttonBorderColor, setButtonBorderColor] = useState(
          appearanceSettings.buttonBorderColor
        );

        const [buttonTextColor, setButtonTextColor] = useState(
          appearanceSettings.buttonColor
        );

        async function handleBackgroundColorChange({ hex }) {
          setBackgroundColor(hex);
          appearanceSettings.backgroundColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleGradient1ColorChange({ hex }) {
          setGradientColor1(hex);
          appearanceSettings.gradientColor1 = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "8 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleGradient2ColorChange({ hex }) {
          setGradientColor2(hex);
          appearanceSettings.gradientColor2 = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "9 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleSectionColorChange({ hex }) {
          setSectionColor(hex);
          appearanceSettings.sectionColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleNameColorChange({ hex }) {
          setNameColor(hex);
          appearanceSettings.nameColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleBioColorChange({ hex }) {
          setBioColor(hex);
          appearanceSettings.bioColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleLocationColorChange({ hex }) {
          setLocationColor(hex);
          appearanceSettings.locationColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleButtonBackgroundColorChange({ hex }) {
          setButtonBackgroundColor(hex);
          appearanceSettings.buttonBackgroundColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleButtonBorderColorChange({ hex }) {
          setButtonBorderColor(hex);
          appearanceSettings.buttonBorderColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        async function handleButtonTextColorChange({ hex }) {
          setButtonTextColor(hex);
          appearanceSettings.buttonColor = hex;
          /*const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }*/
        }

        const [backgroundColorPickerVisible, setBackgroundColorPickerVisible] =
          useState(false);

        const [gradient1PickerVisible, setGradient1PickerVisible] =
          useState(false);

        const [gradient2PickerVisible, setGradient2PickerVisible] =
          useState(false);

        async function onSelectCallback(section, type) {
          let response;
          switch (type) {
            case "background":
              appearanceSettings.backgroundType = section;
              response = await client
                .mutate({
                  mutation: UPDATE_APPEARANCE_SETTINGS,
                  variables: {
                    email: userDetails.email,
                    appearanceSettings: JSON.stringify(appearanceSettings),
                  },
                })
                .catch((error) => {
                  console.error("Error verifying user:", error);
                });
              if (response && response.data && response.data.updateUser) {
                console.log(
                  "2 setting appearance settings to:",
                  JSON.parse(response.data.updateUser.appearanceSettings)
                );
                setUserDetails(response.data.updateUser);
                setAppearanceSettings(
                  JSON.parse(response.data.updateUser.appearanceSettings)
                );
              }
              break;

            case "buttonBackground":
              appearanceSettings.buttonType = section;
              response = await client
                .mutate({
                  mutation: UPDATE_APPEARANCE_SETTINGS,
                  variables: {
                    email: userDetails.email,
                    appearanceSettings: JSON.stringify(appearanceSettings),
                  },
                })
                .catch((error) => {
                  console.error("Error verifying user:", error);
                });
              if (response && response.data && response.data.updateUser) {
                console.log(
                  "2 setting appearance settings to:",
                  JSON.parse(response.data.updateUser.appearanceSettings)
                );
                setUserDetails(response.data.updateUser);
                setAppearanceSettings(
                  JSON.parse(response.data.updateUser.appearanceSettings)
                );
              }
              break;
          }
        }

        async function setBorderRadius(borderRadius) {
          appearanceSettings.buttonBorderRadius = borderRadius;
          //userDetails.appearanceSettings = JSON.stringify(appearanceSettings);
          const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }
        }

        async function setBackgroundImageURL(url) {
          appearanceSettings.backgroundURL = url;
          const response = await client
            .mutate({
              mutation: UPDATE_APPEARANCE_SETTINGS,
              variables: {
                email: userDetails.email,
                appearanceSettings: JSON.stringify(appearanceSettings),
              },
            })
            .catch((error) => {
              console.error("Error verifying user:", error);
            });
          if (response && response.data && response.data.updateUser) {
            console.log(
              "2 setting appearance settings to:",
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
            setUserDetails(response.data.updateUser);
            setAppearanceSettings(
              JSON.parse(response.data.updateUser.appearanceSettings)
            );
          }
        }

        return (
          <View vertical className={styles.contentContainer}>
            <View centerVertically className={styles.titleContainer}>
              <H6>
                <span className={styles.darkerText}>devpa.ge/</span>
                {userDetails.link}
              </H6>
              <Spacer size="small" />
              <Link
                target="_blank"
                to={"https://devpa.ge/" + userDetails.link}
              >
                <Tooltip
                  className={styles.tooltip}
                  label="Open link in a new tab"
                >
                  <ExternalLink color="var(--neutral-100)" size="16" />
                </Tooltip>
              </Link>
              <View className={styles.rightContainer} gap="medium">
                {(sectionData === null || sectionData.length === 0) &&
                (appearanceSettings === null ||
                  appearanceSettings.length === 0) ? (
                  <PrimaryButton
                    disabled
                    className={styles.iconButton}
                    size="small"
                  >
                    <Check size="16" />
                    Nothing to publish
                  </PrimaryButton>
                ) : userDetails.published === null ||
                  userDetails.publishedAppearance === null ||
                  userDetails.published !== JSON.stringify(sectionData) ||
                  userDetails.publishedAppearance !==
                    JSON.stringify(appearanceSettings) ? (
                  <PrimaryButton
                  spinner
                    className={styles.iconButton}
                    size="small"
                    onClick={() => publish()}
                  >
                    <Upload size="16" />
                    Publish
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    disabled
                    className={styles.iconButton}
                    size="small"
                  >
                    <Check size="16" />
                    Up to date
                  </PrimaryButton>
                )}
              </View>
            </View>
            <View
              vertical
              className={`${styles.content} ${styles.lightContent}`}
            >
              <Spacer size="small" />
              <View vertical className={styles.appearanceContainer}>
                <H6>Background</H6>
                <Spacer size="large" />
                <View vertical className={styles.appearanceBlock}>
                  <RadioCellContainer
                    rows={3}
                    type="background"
                    onSelectCallback={onSelectCallback}
                    selected={appearanceSettings.backgroundType}
                    data={backgroundData}
                  />
                  <Spacer size="large" />
                  <View>
                    {appearanceSettings.backgroundType === 1 && (
                      <View vertical className={styles.colorPickerContainer}>
                        <Menu>
                          <MenuButton
                            className={styles.menuTitle}
                            rightIcon={<ChevronDown />}
                          >
                            <H6 className={styles.smallTitle}>
                              Background color
                            </H6>
                            <Spacer size="medium" />
                            <ComponentTextInput
                              component={
                                <View
                                  size="medium"
                                  className={styles.colorBlock}
                                  style={{
                                    backgroundColor: backgroundColor,
                                  }}
                                ></View>
                              }
                              size="small"
                              value={backgroundColor.toUpperCase()}
                              className={styles.colorInput}
                            />
                          </MenuButton>
                          <MenuList>
                            <ChromePicker
                              color={backgroundColor}
                              onChangeComplete={handleBackgroundColorChange}
                            />
                          </MenuList>
                        </Menu>
                      </View>
                    )}
                    {appearanceSettings.backgroundType === 2 && (
                      <View vertical gap="large">
                        <View vertical className={styles.colorPickerContainer}>
                          <Menu>
                            <MenuButton
                              className={styles.menuTitle}
                              rightIcon={<ChevronDown />}
                            >
                              <H6 className={styles.smallTitle}>
                                Top gradient color
                              </H6>
                              <Spacer size="medium" />
                              <ComponentTextInput
                                component={
                                  <View
                                    size="medium"
                                    className={styles.colorBlock}
                                    style={{
                                      backgroundColor: gradientColor1,
                                    }}
                                  ></View>
                                }
                                size="small"
                                value={gradientColor1.toUpperCase()}
                                className={styles.colorInput}
                              />
                            </MenuButton>
                            <MenuList>
                              <ChromePicker
                                color={gradientColor1}
                                onChange={handleGradient1ColorChange}
                              />
                            </MenuList>
                          </Menu>
                          <Spacer size="large" />
                          <Menu>
                            <MenuButton
                              className={styles.menuTitle}
                              rightIcon={<ChevronDown />}
                            >
                              <H6 className={styles.smallTitle}>
                                Bottom gradient color
                              </H6>
                              <Spacer size="medium" />
                              <ComponentTextInput
                                component={
                                  <View
                                    size="medium"
                                    className={styles.colorBlock}
                                    style={{
                                      backgroundColor: gradientColor2,
                                    }}
                                  ></View>
                                }
                                size="small"
                                value={gradientColor2.toUpperCase()}
                                className={styles.colorInput}
                              />
                            </MenuButton>
                            <MenuList>
                              <ChromePicker
                                color={gradientColor2}
                                onChange={handleGradient2ColorChange}
                              />
                            </MenuList>
                          </Menu>
                        </View>
                      </View>
                    )}
                    {appearanceSettings.backgroundType === 3 && (
                      <View vertical className={styles.colorPickerContainer}>
                        <H6 className={styles.smallTitle}>
                          Background image URL
                        </H6>
                        <Spacer size="medium" />
                        <TextInput
                        size="small"
                        onChange={(e) => setBackgroundImageURL(e.target.value)}
                          value={appearanceSettings.backgroundURL}
                          placeholder="E.g. https://example.com/background.png"
                        />
                      </View>
                    )}
                  </View>
                </View>
                <Spacer size="large" />
                <H6>Text</H6>
                <Spacer size="large" />
                <View vertical className={styles.appearanceBlock}>
                  <View vertical>
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Section color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: sectionColor }}
                            ></View>
                          }
                          size="small"
                          value={sectionColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={sectionColor}
                          onChange={handleSectionColorChange}
                        />
                      </MenuList>
                    </Menu>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Name color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: nameColor }}
                            ></View>
                          }
                          size="small"
                          value={nameColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={nameColor}
                          onChange={handleNameColorChange}
                        />
                      </MenuList>
                    </Menu>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Bio color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: bioColor }}
                            ></View>
                          }
                          size="small"
                          value={bioColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={bioColor}
                          onChange={handleBioColorChange}
                        />
                      </MenuList>
                    </Menu>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Location color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: locationColor }}
                            ></View>
                          }
                          size="small"
                          value={locationColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={locationColor}
                          onChange={handleLocationColorChange}
                        />
                      </MenuList>
                    </Menu>
                  </View>
                </View>
                <Spacer size="large" />
                <H6>Buttons</H6>
                <Spacer size="large" />
                <View vertical className={styles.appearanceBlock}>
                  <View vertical>
                    <View vertical className={styles.colorPickerContainer}>
                      <H6 className={styles.smallTitle}>Border radius</H6>
                      <Spacer size="medium" />
                      <TextInput
                        size="small"
                        type="number"
                        onChange={(e) => setBorderRadius(e.target.value)}
                        value={appearanceSettings.buttonBorderRadius}
                        placeholder="E.g. 8"
                      />
                    </View>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Background color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: buttonBackgroundColor }}
                            ></View>
                          }
                          size="small"
                          value={buttonBackgroundColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={buttonBackgroundColor}
                          onChange={handleButtonBackgroundColorChange}
                        />
                      </MenuList>
                    </Menu>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Border color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: buttonBorderColor }}
                            ></View>
                          }
                          size="small"
                          value={buttonBorderColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={buttonBorderColor}
                          onChange={handleButtonBorderColorChange}
                        />
                      </MenuList>
                    </Menu>
                    <Spacer size="large" />
                    <Menu>
                      <MenuButton
                        className={styles.menuTitle}
                        rightIcon={<ChevronDown />}
                      >
                        <H6 className={styles.smallTitle}>Text color</H6>
                        <Spacer size="medium" />
                        <ComponentTextInput
                          component={
                            <View
                              size="medium"
                              className={styles.colorBlock}
                              style={{ backgroundColor: buttonTextColor }}
                            ></View>
                          }
                          size="small"
                          value={buttonTextColor.toUpperCase()}
                          className={styles.colorInput}
                        />
                      </MenuButton>
                      <MenuList>
                        <ChromePicker
                          color={buttonTextColor}
                          onChange={handleButtonTextColorChange}
                        />
                      </MenuList>
                    </Menu>
                  </View>
                </View>
              </View>
              <Spacer size="small" />
            </View>
            <View
              gap="medium"
              centerVertically
              className={styles.bottomButtonContainer}
            >
              <PrimaryButton className={styles.iconButton} size="small">
                <RotateCcw size="16" />
                Reset to default
              </PrimaryButton>
            </View>
          </View>
        );

      case "Admin":
        const [verifyUser, setVerifyUser] = useState("");

        const [unverifyUser, setUnverifyUser] = useState("");

        return (
          <View vertical className={styles.contentContainer}>
            <View
              vertical
              className={`${styles.content} ${styles.lightContent}`}
            >
              <Spacer size="small" />
              <View vertical className={styles.appearanceContainer}>
                <H6>Admin Dashboard</H6>
                <Spacer size="medium" />
                <View vertical className={styles.appearanceBlock}>
                  <H6>Verify user</H6>
                  <Spacer size="medium" />
                  <TextInput
                    value={verifyUser}
                    onChange={(e) => setVerifyUser(e.target.value)}
                    placeholder="Enter an email address"
                  />
                  <Spacer size="medium" />
                  <PrimaryButton
                    onClick={() => verify(verifyUser, 1)}
                    className={styles.iconButton}
                    size="small"
                  >
                    Verify
                  </PrimaryButton>
                  <Spacer size="large" />
                  <H6>Unverify user</H6>
                  <Spacer size="medium" />
                  <TextInput
                    value={unverifyUser}
                    onChange={(e) => setUnverifyUser(e.target.value)}
                    placeholder="Enter an email address"
                  />
                  <Spacer size="medium" />
                  <PrimaryButton
                    onClick={() => verify(unverifyUser, 0)}
                    className={styles.iconButton}
                    size="small"
                  >
                    Unverify
                  </PrimaryButton>
                </View>
                <Spacer size="large" />
              </View>
              <Spacer size="small" />
            </View>
            <View
              gap="medium"
              centerVertically
              className={styles.bottomButtonContainer}
            >
              <PrimaryButton className={styles.iconButton} size="small">
                <RotateCcw size="16" />
                Reset to default
              </PrimaryButton>
            </View>
          </View>
        );
    }
  }

  return (
    <View centerVertically centerHorizontally>
      <View className={styles.container}>
        <CurrentTab />
        {(dashboardTab === "Links" || dashboardTab === "Appearance") && (
          <View className={styles.previewContainer}>
            <View className={styles.preview}>
              <View className={styles.previewContent}>
                <User data={sectionData} userDeets={userDetails} preview />
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
