import { Tooltip } from "evergreen-ui";
import React, { useContext, useEffect, useState } from "react";
import { Check, X } from "react-feather";
import ProfilePicture from "../images/ProfilePicture";
import H6 from "../text/heading/H6";
import Paragraph from "../text/paragraph/Paragraph";
import View from "../view/View";
import Separator from "../view/impl/Separator";
import styles from "./styles/Modal.module.css";
import { OverlayContext } from "../../context/OverlayProvider";

export default function Modal(props) {
  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const { closeOverlay } = useContext(OverlayContext);

  return (
    <div onClick={handleModalClick} vertical className={`${styles.defaults}`}>
      <View className={styles.titleContainer}>
        <View centerVertically centerHorizontally>
          <H6 className={styles.smallTitle}>{props.title}</H6>
          <View className={styles.rightAligned}>
            <button
              className={styles.closeButton}
              onClick={() => closeOverlay()}
            >
              <X size="18" />
            </button>
          </View>
        </View>
      </View>
      <Separator className={styles.separator} />
      {props.children}
    </div>
  );
}
