import React, { createContext, useState } from "react";
import Overlay from "../components/overlay/Overlay";
import Paragraph from "../components/text/paragraph/Paragraph";

// Create the overlay context
export const OverlayContext = createContext();

// Create a provider component for the overlay context
export function OverlayProvider({ children }) {

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayContent, setOverlayContent] = useState(<Paragraph>Default</Paragraph>);

  const openOverlay = (content) => {
    setOverlayContent(content);
    setIsOverlayVisible(true);
  };

  const closeOverlay = () => {
    setIsOverlayVisible(false);
  };

  const overlayContextValue = {
    isOverlayVisible,
    openOverlay,
    closeOverlay
  };

  return (
    <OverlayContext.Provider value={overlayContextValue}>
      <Overlay isVisible={isOverlayVisible}>{overlayContent}</Overlay>
      {children}
    </OverlayContext.Provider>
  );
}
