import React from "react";
import styles from "./styles/ProfilePicture.module.css";
import H2 from "../../text/heading/H2";
import View from "../View";

export default function ProfilePicture({
  className,
  onClick,
  size,
  name,
  url,
}) {
  const getInitials = (name) => {
    const initials = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
    return initials;
  };
  return (
    <button
      onClick={onClick}
      className={`${styles.defaults} ${className} ${
        size === "small" && styles.small
      }`}
    >
      {url ? (
        <img src={url} alt={name} className={`${styles.viewDefaults} ${
          size === "small" && styles.smallView
        }`} />
      ) : (
        <View className={`${styles.viewDefaults} ${
          size === "small" && styles.smallView
        }`}>
          <H2
            className={`${styles.initials} ${
              size === "small" && styles.smallText
            }`}
          >
            {getInitials(name)}
          </H2>
        </View>
      )}
    </button>
  );
}
